import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUtilService } from '@shared/services/app-util.service';
import { DataShareService } from '@shared/services/data-share.service';
import { SharedValidator } from '@shared/validators/shared.validator';
import { DfpsCommonValidators, DfpsFormValidationDirective, DirtyCheck, FormValidationError, SET } from 'dfps-web-lib';
import { ToastrService } from 'ngx-toastr';
import { CpaAccountService } from '../services/cpa-account.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cpa-account',
  templateUrl: './cpa-account.component.html',
  styleUrls: [
    "../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './cpa-account.component.css']
})
export class CpaAccountComponent extends DfpsFormValidationDirective implements OnInit {
  cpaAccountRegisterForm: FormGroup;
  cpaAccountLookup$: Observable<any>;
  renderCpaAccount: boolean;
  validationErrorsLength = 0;
  validationErrors: FormValidationError[] = [];
  requestProcessing: Boolean;
  cpaAccountInfo: any;
  @ViewChild('errors', { static: false }) errorElement: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private cpaAccountService: CpaAccountService,
    private shareService: DataShareService,
    private toasterService: ToastrService,
    private router: Router,
    private appUtilService: AppUtilService,
    public store: Store<{ dirtyCheck: DirtyCheck }>,
  ) {
    super(store);
  }

  createForm() {
    this.cpaAccountRegisterForm = this.formBuilder.group(
      {
        agencyName: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        primaryPhone: ['', [Validators.required, SharedValidator.phoneNumberPattern]],
        agencyPhoneNumber: ['', [Validators.required, SharedValidator.phoneNumberPattern]],
        mailingAddress0: ['', [Validators.required]],
        mailingAddress1: [''],
        mailingCity: ['', [Validators.required, DfpsCommonValidators.cityPattern]],
        mailingState: ['TX', [Validators.required]],
        mailingCounty: ['', [Validators.required]],
        mailingZip: ['', [Validators.required, DfpsCommonValidators.zipPattern]],
        sameMailingAddress: [false],
        physicalAddress0: ['', [Validators.required]],
        physicalAddress1: [''],
        physicalCity: ['', [Validators.required, DfpsCommonValidators.cityPattern]],
        physicalState: ['TX', [Validators.required]],
        physicalCounty: ['', [Validators.required]],
        physicalZip: ['', [Validators.required, DfpsCommonValidators.zipPattern]]
      });
  }

  ngOnInit(): void {
    this.renderCpaAccount = false;
    this.createForm();

    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.cpaAccountService.getCpaAccountPageLookupsES().then(data => {
          this.cpaAccountLookup$ = data;
        })
      } else {
        this.cpaAccountService.getCpaAccountPageLookups().then(data => {
          this.cpaAccountService.cpaAccountPageLookups = data;
          this.cpaAccountLookup$ = data;
        });
      }
    })
    this.loadCpaAccountData();
  }

  loadCpaAccountData() {
    if (this.shareService.getDataFromMap('familyProfileInfo')) {
      this.cpaAccountInfo = this.shareService.getDataFromMap('familyProfileInfo');
      this.cpaAccountRegisterForm.patchValue({
        firstName: this.cpaAccountInfo.firstName,
        lastName: this.cpaAccountInfo.lastName,
        primaryPhone: this.cpaAccountInfo.primaryPhone,
        agencyPhoneNumber: this.cpaAccountInfo.secondaryPhone,
        mailingAddress0: this.cpaAccountInfo.mailingAddress0,
        mailingAddress1: this.cpaAccountInfo.mailingAddress1,
        mailingCity: this.cpaAccountInfo.mailingCity,
        mailingState: this.cpaAccountInfo.mailingState,
        mailingCounty: this.cpaAccountInfo.mailingCounty,
        mailingZip: this.cpaAccountInfo.mailingZip,
        sameMailingAddress: this.cpaAccountInfo.sameMailingAddress,
        physicalAddress0: this.cpaAccountInfo.physicalAddress0,
        physicalAddress1: this.cpaAccountInfo.physicalAddress1,
        physicalCity: this.cpaAccountInfo.physicalCity,
        physicalState: this.cpaAccountInfo.physicalState,
        physicalCounty: this.cpaAccountInfo.physicalCounty,
        physicalZip: this.cpaAccountInfo.physicalZip
      });
      this.renderCpaAccount = true;
    }
  }

  changeState() {
    if (this.cpaAccountRegisterForm.value.mailingState !== 'TX') {
      this.cpaAccountRegisterForm.patchValue({ mailingCounty: '999' });
    } else if (this.cpaAccountRegisterForm.value.mailingState == 'TX' && this.cpaAccountRegisterForm.value.mailingCounty === '999') {
      this.cpaAccountRegisterForm.patchValue({ mailingCounty: '' });
    }
    if (this.cpaAccountRegisterForm.value.physicalState !== 'TX') {
      this.cpaAccountRegisterForm.patchValue({ physicalCounty: '999' });
    } else if (this.cpaAccountRegisterForm.value.physicalState == 'TX' && this.cpaAccountRegisterForm.value.physicalCounty === '999') {
      this.cpaAccountRegisterForm.patchValue({ physicalCounty: '' });
    }
  }

  changeCounty() {
    if (this.cpaAccountRegisterForm.value.mailingState !== 'TX' && this.cpaAccountRegisterForm.value.mailingCounty !== '999') {
      this.cpaAccountRegisterForm.patchValue({ mailingCounty: '999' });
      alert('county selection not available outside of Texas');
    } else if (this.cpaAccountRegisterForm.value.mailingState == 'TX' && this.cpaAccountRegisterForm.value.mailingCounty === '999') {
      this.cpaAccountRegisterForm.patchValue({ mailingCounty: '' });
    }
    if (this.cpaAccountRegisterForm.value.physicalState !== 'TX' && this.cpaAccountRegisterForm.value.physicalCounty !== '999') {
      this.cpaAccountRegisterForm.patchValue({ physicalCounty: '999' });
      alert('county selection not available outside of Texas');
    } else if (this.cpaAccountRegisterForm.value.physicalState == 'TX' && this.cpaAccountRegisterForm.value.physicalCounty === '999') {
      this.cpaAccountRegisterForm.patchValue({ physicalCounty: '' });
    }
  }

  pasteNumberOnly(event): void {
    SharedValidator.pasteNumberOnly(event);
  }

  validateNumberOnly(event): void {
    SharedValidator.validateNumberOnly(event);
  }

  sameMailingAddress() {
    if (this.cpaAccountRegisterForm.value.sameMailingAddress) {
      this.cpaAccountRegisterForm.patchValue({
        physicalAddress0: this.cpaAccountRegisterForm.value.mailingAddress0,
        physicalAddress1: this.cpaAccountRegisterForm.value.mailingAddress1,
        physicalCity: this.cpaAccountRegisterForm.value.mailingCity,
        physicalState: this.cpaAccountRegisterForm.value.mailingState,
        physicalCounty: this.cpaAccountRegisterForm.value.mailingCounty,
        physicalZip: this.cpaAccountRegisterForm.value.mailingZip,
      });
    } else {
      this.cpaAccountRegisterForm.patchValue({
        physicalAddress0: this.cpaAccountRegisterForm.value.mailingAddress0 ? this.cpaAccountRegisterForm.value.mailingAddress0 : '',
        physicalAddress1: this.cpaAccountRegisterForm.value.mailingAddress1 ? this.cpaAccountRegisterForm.value.mailingAddress1 : '',
        physicalCity: this.cpaAccountRegisterForm.value.mailingCity ? this.cpaAccountRegisterForm.value.mailingCity : '',
        physicalState: this.cpaAccountRegisterForm.value.mailingState ? this.cpaAccountRegisterForm.value.mailingState : '',
        physicalCounty: this.cpaAccountRegisterForm.value.mailingCounty ? this.cpaAccountRegisterForm.value.mailingCounty : '',
        physicalZip: this.cpaAccountRegisterForm.value.mailingZip ? this.cpaAccountRegisterForm.value.mailingZip : '',
      });
    }
  }

  onSaveCpaAccount() {
    if (this.validateFormGroup(this.cpaAccountRegisterForm)) {
      this.requestProcessing = true;
      const payload = Object.assign(this.cpaAccountInfo, this.cpaAccountRegisterForm.value);
      payload.secondaryPhone = this.cpaAccountRegisterForm.controls.agencyPhoneNumber.value;
      this.cpaAccountService.saveCpaAccount(payload).subscribe((res) => {
        if (res) {
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          this.router.navigate(['/child/childSearch']).then(() => {
            window.location.reload();
          });
        }
        this.requestProcessing = false;
        window.scrollTo(0, 0);
      }, error => {
        this.requestProcessing = false;
        this.toasterService.error(error, 'saveCpaAccount Error');
      });
    } else {
      if (this.errorElement) {
        // for focussing errors.
        setTimeout(() => {
          this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus();
        }, 1000);
      }
    }
  }

}
