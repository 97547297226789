<app-profile-percent-success-msg [familyProfileDetails]="displayAdoptionInterestsDetails"
    [successMsg]="familyProfileSuccessMsg"></app-profile-percent-success-msg>

<div #errors>
    <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
        [validationErrorsLength]="validationErrorsLength">
    </dfps-form-validation>
</div>

<div class="d-flex col-sm-12 justify-content-center">
    <p-progressSpinner *ngIf="!renderFamilyProfile"></p-progressSpinner>
</div>

<form [formGroup]="adoptionInterestsForm" (ngSubmit)="onSaveAdoptionInterestsForm()">
    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">Adoption Interests</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4 pt-3">
        <p class="col-sm-12 boldBody d-inline-flex mb-0"> We would like to learn more about your adoption and parenting
            preferences. This information will be used to match your family's strengths with children in Texas waiting
            to be matched with a forever family. </p>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-lg-flex">
            <label for="maxChildrenAdopt"
                class="col-md-8 col-lg-5 col-xl-3 col-form-label col-form-label-sm requiredDisplay">
                Max # of Children willing to Adopt? </label>
            <dfps-select _id="maxChildrenAdopt" ariaLabel="Max # of Children willing to Adopt?"
                formControlName="maxChildrenAdopt" aria-required="true"
                [dropDownValues]="adoptionInterestsLookup$ ? adoptionInterestsLookup$['maxChildrenAdopt']:[]">
            </dfps-select>
        </div>
    </div>
    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-lg-flex">
            <label for="maxAgeChildrenToAdopt"
                class="col-md-8 col-lg-5 col-xl-3 col-form-label col-form-label-sm requiredDisplay"> Max
                age of Children willing to Adopt? </label>
            <dfps-select _id="maxAgeChildrenToAdopt" ariaLabel="Max age of Children willing to Adopt?"
                formControlName="maxAgeChildrenToAdopt" aria-required="true"
                [dropDownValues]="adoptionInterestsLookup$ ? adoptionInterestsLookup$['maxAgeChildrenToAdopt']:[]">
            </dfps-select>
        </div>
    </div>
    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-lg-flex">
            <label for="childAdoptGenderPreference"
                class="col-md-8 col-lg-5 col-xl-3 col-form-label col-form-label-sm requiredDisplay">
                Gender of Children willing to Adopt? </label>
            <dfps-select _id="childAdoptGenderPreference" ariaLabel="Gender of Children willing to Adopt?"
                formControlName="childAdoptGenderPreference" aria-required="true"
                [dropDownValues]="adoptionInterestsLookup$ ? adoptionInterestsLookup$['genderPreference']:[]">
            </dfps-select>
        </div>
    </div>
    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-lg-flex">
            <label for="childAdoptRacePreference"
                class="col-md-8 col-lg-5 col-xl-3 col-form-label col-form-label-sm requiredDisplay">
                Race of Children willing to Adopt? </label>
            <dfps-select _id="childAdoptRacePreference" ariaLabel="Race of Children willing to Adopt?"
                formControlName="childAdoptRacePreference" aria-required="true"
                [dropDownValues]="adoptionInterestsLookup$ ? adoptionInterestsLookup$['racePreference']:[]">
            </dfps-select>
        </div>
    </div>
    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-lg-flex">
            <label for="childAdoptEthnicityPreference"
                class="col-md-8 col-lg-5 col-xl-3 col-form-label col-form-label-sm requiredDisplay">
                Ethnicity of children willing to adopt? </label>
            <dfps-select _id="childAdoptEthnicityPreference" ariaLabel="Ethnicity of children willing to adopt?"
                formControlName="childAdoptEthnicityPreference" aria-required="true"
                [dropDownValues]="adoptionInterestsLookup$ ? adoptionInterestsLookup$['ethnicityPreference']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">Match Rank Order Preferences </h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4 pt-3">
        <p class="col-sm-12 boldBody mb-0"> Select the child characteristic below and use the <span
                class="font-italic">Move Up</span> and <span class="font-italic">Move Down</span> buttons to arrange the
            weighted search criteria in the order of importance from highest to lowest. The match rank order is used to
            calculate the child match percentage based on your family profile settings and the rank order listed below.
        </p>
    </div>

    <div class="row pl-5 pt-3">
        <div class="card">
            <p-orderList appAccessibilityPOrderList [value]="adoptionInterestsForm.value.searchRank"
                [listStyle]="{ height: '29rem' }" header="Child Characteristic">
                <ng-template let-characteristic pTemplate="item">{{ characteristic.decode }} </ng-template>
            </p-orderList>
        </div>
    </div>

    <div class="row pl-4">
        <div class="d-flex col-sm-12 pt-3 justify-content-end">
            <dfps-button type="submit" size="small" label="Save Changes" _id="saveAdoptionInterests"
                [disabled]="requestProcessing"></dfps-button>
        </div>
    </div>
</form>