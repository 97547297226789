import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AuthTareService } from '@shared/services/auth-tare.service';
import { DataShareService } from '@shared/services/data-share.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-primary-nav',
  templateUrl: './primary-nav.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './primary-nav.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PrimaryNavComponent implements OnInit {
  isChildTabActive: boolean;
  familyId: number;

  @Input() isUserLoggedIn: boolean;
  isRoleFamily = this.authTareService.hasValidRole(this.authTareService.RoleFamily);

  constructor(
    private router: Router,
    private shareService: DataShareService,
    private authTareService: AuthTareService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.familyId = this.shareService.getDataFromMap('familyProfileInfo') ? this.shareService.getDataFromMap('familyProfileInfo').familyId : null;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url.includes('/child')) {
          this.isChildTabActive = true;
        }
        else this.isChildTabActive = false;
      });
  }
}
