import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InformationMeetingsComponent } from './meetings/information-meetings.component';

const infoMeetingsRoutes: Routes = [
    {
        path: 'information-meetings',
        component: InformationMeetingsComponent,
        data: {
            title: 'Information Meetings', routerLinkRole: '/information-meetings', tabTitle: 'Information Meetings'
        }
    },

];

@NgModule({
    imports: [RouterModule.forChild(infoMeetingsRoutes)],
    exports: [RouterModule]
})
export class InfoMeetingsRoutingModule { }
