<div id="access">
    <a href="#pageContent" lang="en">skip to content [x]</a>&nbsp;
    <a name="top"></a>
    <a href="#" title="larger font size" class="increaseFont" lang="en"> larger font [+]</a>&nbsp;
    <a href="#" title="smaller font size" lang="en" class="decreaseFont"> smaller font [-]</a>&nbsp;
    <a href="#" title="normal font size" lang="en" class="resetFont"> normal font [o]</a>&nbsp;
</div>
<div style="margin: 25px;">
    <header class="col" style="min-width: auto;">
        <div class="row" id="header"> </div>
    </header>
    <div class="Content">
        <div class="sidenav">
            <div id="sidenav"></div>
        </div>
    </div>
</div>