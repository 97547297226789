<ng-container *transloco="let sp">
    <div class="row">
        <h1 class="col-sm-12">{{sp('siblingGroup')}} <span class="notranslate">{{siblingProfileResponse?.name}}</span>
        </h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>

    <div class="row pl-3 pt-1" *ngIf="isUserLoggedIn else generalSearch">
        <div class="col-6 col-sm-4 pt-2">
            <div *ngIf="(siblingProfileResponse?.inquiryStatus!==''&&siblingProfileResponse?.inquiryStatus!=null) else inquiryOnSibling"
                class="alert alert-info mb-0" role="alert">
                {{sp('currentInquiryStatus')}}:{{siblingProfileResponse?.inquiryStatus}}</div>
            <ng-template #inquiryOnSibling>
                <img role="button" tabindex="0" src="/assets/images/inquiry.png" class="img-fluid mr-3"
                    [alt]="sp('inquiryOnsiblingGroup')  + ' ' + sp('icon')"
                    [attr.aria-label]="sp('inquiryOnsiblingGroup')" (click)="inquirySibling()"
                    (keypress)="inquirySibling()">
                <div>{{sp('inquiryOnsiblingGroup')}}</div>
            </ng-template>
        </div>
        <div class="text-left col-6 col-sm-4 pt-2">
            <span [ngClass]="siblingProfileResponse?.isUserBookmark ?'glyphicon glyphicon-bookmark':'pi pi-bookmark'"
                role="button" tabindex="0"
                [attr.aria-label]="siblingProfileResponse?.isUserBookmark ? sp('removeBookmark'):sp('bookmarkSibling')"
                (click)="toggleBookmark()" (keypress)="toggleBookmark()"></span>
            <div>{{siblingProfileResponse?.isUserBookmark ? sp('removeBookmark'):sp('bookmarkSibling')}}</div>
        </div>
    </div>

    <ng-template #generalSearch>
        <div class="row">
            <div class="d-flex col-sm-4 col-lg-3 pt-3">
                <dfps-button add-button size="small" label="{{sp('infoOnAdopt')}}" _id="informationGettingStartedAdopt">
                </dfps-button>
            </div>
            <div class="d-flex col-sm-4 col-lg-3 pt-3">

            </div>
        </div>
    </ng-template>

    <div class="row pl-3">
        <div class="d-flex col-lg-4 pt-2">
            <img [src]="siblingProfileResponse?.primaryMedia" class="img-fluid searchResultsImageSize"
                [alt]="sp('potraitOf') + ' ' + siblingProfileResponse?.firstName + '' + siblingProfileResponse?.lastName + ' ' + sp('tareId') + ' ' + siblingProfileResponse?.idSiblingGroup">
        </div>
        <div class="col-lg-8">
            <div class="row" *ngFor="let list of siblingProfileResponse?.siblingGroupMembers">
                <div class="col-lg-4 col-xl-3 px-0 pt-2">
                    <img [src]="list?.mediaUrl" class="img-fluid searchResultsImageSize"
                        [alt]="sp('potraitOf') + ' ' + list?.firstName + '' + list?.lastName + ' ' + sp('childId') + ' ' + list?.idSiblingGroup">
                </div>
                <div class="col-lg-8 col-xl-9 pt-2 pr-0">
                    <div class="row">
                        <p class="col-6 col-sm-2 boldBody d-inline-flex pr-0 mb-0"><strong>{{sp('name')}}: </strong></p>
                        <p class="col-6 col-sm-8 d-inline-flex mb-0">
                            <a class="notranslate" [routerLink]="['/child/childSearch/childProfile']"
                                [queryParams]="{id:list?.idChild, idSibling:list?.idSiblingGroup }"
                                [attr.aria-label]="sp('select') + ' '  + list.firstName + ' ' + sp('linkNavToProfilePage')">
                                {{list?.firstName}}</a>
                        </p>
                    </div>
                    <div class="row pt-1">
                        <p class="col-6 col-sm-2 boldBody d-inline-flex pr-0 mb-0"><strong>{{sp('age')}}: </strong></p>
                        <p class="col-6 col-sm-8 d-inline-flex mb-0"> {{list?.age}} </p>
                    </div>
                    <div class="row pt-1">
                        <h3 class="col-sm-12 h6 font-weight-bold">{{sp('about')}}<span
                                class="notranslate">{{list?.firstName}}</span></h3>
                        <div class="col-sm-12">
                            <hr aria-hidden="true" />
                        </div>
                    </div>
                    <div class="row pt-1">
                        <p class="col-sm-12 boldBody" [innerHTML]="list?.txtTagLine"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row pl-3 pt-4">
        <div class="col-sm-12">
            <p class="col-6 col-sm-3 col-xl-2 boldBody d-inline-flex mb-0"><strong>{{sp('tareId')}}:</strong></p>
            <span> {{siblingProfileResponse?.idSiblingGroup}}</span>
        </div>
    </div>
    <div class="row pl-3">
        <div class="col-sm-12 pt-3">
            <p class="col-3 col-sm-3 col-xl-2 boldBody d-inline-flex mb-0"><strong>{{sp('region')}}:</strong></p>
            <span> {{siblingProfileResponse?.region}}</span>
        </div>
    </div>

    <ng-template [ngIf]="isUserLoggedIn">
        <div class="row pl-3">
            <div class="col-sm-12 pt-3">
                <p class="col-3 col-sm-3 col-xl-2 boldBody d-inline-flex mb-0">
                    <strong>{{sp('tareCoordinator')}}:</strong>
                </p>
                <span class="notranslate"> {{siblingProfileResponse?.tareCoOrdinator?.firstName}}
                    <ng-template [ngIf]="siblingProfileResponse?.tareCoOrdinator?.firstName">, </ng-template>
                    {{siblingProfileResponse?.caseworkerContact?.middleName}}
                    {{siblingProfileResponse?.tareCoOrdinator?.lastName}}
                </span>
            </div>
        </div>
        <div class="row pl-3">
            <div class="col-sm-12 pt-3">
                <p class="col-6 col-sm-3 col-xl-2 boldBody d-inline-flex mb-0"><strong>{{sp('phone')}}:</strong></p>
                <span> {{siblingProfileResponse?.tareCoOrdinator?.primaryPhoneNumber}}</span>
            </div>
        </div>
        <div class="row pl-3">
            <div class="col-sm-12 pt-3">
                <p class="col-sm-3 col-xl-2 boldBody d-inline-flex mb-0">
                    <strong>{{sp('email')}}:</strong>
                </p>
                <span> {{siblingProfileResponse?.tareCoOrdinator?.emailAddress}}</span>
            </div>
        </div>

        <div class="row pl-3 pt-4">
            <h2 class="col-sm-12 ">{{sp('siblingGrpNarratives')}}</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>

        <div class="row pl-4 pt-4">
            <h3 class="col-sm-12 pl-3 h4">{{sp('profile')}}</h3>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <p class="col-sm-12 boldBody notranslate"
                [innerHTML]="(activeLangSpanish$ | async) ? (siblingProfileResponse?.es_ProfileText ? siblingProfileResponse?.es_ProfileText : siblingProfileResponse?.profileText) : siblingProfileResponse?.profileText">
            </p>
        </div>

        <div class="row pl-4 pt-4">
            <h3 class="col-sm-12 pl-3 h4">{{sp('familyProfile')}}</h3>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <p class="col-sm-12 boldBody notranslate"
                [innerHTML]="(activeLangSpanish$ | async) ? (siblingProfileResponse?.es_DesiredFamily ? siblingProfileResponse?.es_DesiredFamily : siblingProfileResponse?.desiredFamily) : siblingProfileResponse?.desiredFamily">
            </p>
        </div>
    </ng-template>

    <div class="row pl-3 pt-4" *ngIf="!isUserLoggedIn">
        <h2 class="col-sm-12 ">{{sp('siblingGrpNarratives')}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-3 pt-4">
        <h3 class="col-sm-12 pl-4 h4">{{sp('aboutMe')}}</h3>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-5 pt-3">
        <p class="col-sm-12 boldBody notranslate"
            [innerHTML]="(activeLangSpanish$ | async) ? (siblingProfileResponse?.es_TxtTagLine ? siblingProfileResponse?.es_TxtTagLine : siblingProfileResponse?.txtTagLine) : siblingProfileResponse?.txtTagLine">
        </p>
    </div>

    <ng-template [ngIf]="siblingProfileResponse?.allMedia && siblingProfileResponse?.allMedia?.length > 0">
        <div class="row pl-3 pt-4">
            <h3 class="col-sm-12 pl-3 h4">{{sp('media')}}</h3>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-4 pt-3">
            <div class="col-sm-12">
                <ng-template ngFor let-media let-index [ngForOf]="this.groupedMedia | keyvalue">
                    <h4 class="h5">{{media?.key}}</h4>
                    <ng-template ngFor let-list let-i="index" [ngForOf]="media?.value">
                        <img *ngIf="list?.mediaUrl!=null" [src]="list?.mediaUrl"
                            class="img-fluid mr-3 mb-2 searchResultsImageSize"
                            [alt]="sp('potraitOf') + ' ' + siblingProfileResponse?.firstName + '' + siblingProfileResponse?.lastName + sp('tareId') + ' ' + siblingProfileResponse?.idSiblingGroup + index">
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </ng-template>
</ng-container>