import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AppInsightMonitoringService } from '@shared/services/AppInsightMonitoringService';
import { userData } from '@shared/services/user-data';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tare-header',
  templateUrl: './tare-header.component.html',
  styleUrls: [
    "../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../.././node_modules/primeng/resources/primeng.min.css",
    "../../../../.././node_modules/primeicons/primeicons.css",
    '../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    '../../../../.././node_modules/dfps-web-lib/resources/styles/css/bootstrap-glyphicons.css',
    './tare-header.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TareHeaderComponent implements OnInit {

  envName: string = environment.environmentName;
  isUserLoggedIn: boolean;
  userInfo: AccountInfo;
  private readonly _destroy = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private router: Router,
    private monitoringService: AppInsightMonitoringService,
    private authService: MsalService,
  ) { }

  ngOnInit(): void {
    this.msalBroadCastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.monitoringService.logPageView("PageUrl", this.router.url);
        const roles = (payload.idTokenClaims as userData)?.extension_Roles;

        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadCastService.inProgress$.pipe
      (filter((interactionStatus: InteractionStatus) =>
        interactionStatus == InteractionStatus.None),
        takeUntil(this._destroy))
      .subscribe(x => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        if (this.isUserLoggedIn) {
          this.userInfo = (this.authService.instance.getAllAccounts()[0] as AccountInfo);
        }
      })

    /* this.authService.loggedInUser().subscribe(
      res => {
        this.userDetails = res;
      },
      error => {
      }); */
  }

  logout() {
    this.authService.logoutRedirect({ postLogoutRedirectUri: environment.redirectUri });
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

}