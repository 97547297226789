<div class="navbar navbar-inverse bg-inverse mb-4 p-0" role="banner">
    <div class="container-fluid">
        <div class="navbar-brand p-0">
            <span class="ml-2 h4 appHeader" *ngIf="envName !=='PRODUCTION'">{{envName}}</span>
        </div>
        <!-- <h1 class="navbar-nav mx-auto appHeader">TARE PUBLIC</h1> -->
        <ul class="navbar-right list-inline mb-0" *ngIf="isUserLoggedIn">
            <li ngbDropdown class="optionPadding list-inline-item marginLeft8 p-0 open">
                <button id="myaccount_style" class="btn btn-default dropdown-toggle notranslate" type="button" ngbDropdownToggle
                    data-toggle="dropdown" aria-expanded="false"> <strong>{{userInfo?.name}} </strong>
                    <span class="caret"></span>
                </button>
                <ul ngbDropdownMenu class="dropdown-menu minWidthSelect onlineHelpBackground"
                    aria-labelledby="myaccount_style">
                    <li ngbDropdownItem>
                        <button class="btn btn-outline-success" (click)="logout()">Sign Out</button>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>