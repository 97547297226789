const dfpsDomainUrl = `https://tareprodstaticcontent-dgbsa2fxdwgzeees.z01.azurefd.net`;
const apiMangerUrl = `https://apimgmt-dfps-tare-nprod1.azure-api.net`;

export const environment = {
  production: false,
  isDeployedAzureCloud: false,
  environmentName: `Dev`,
  disableConsole: true,
  idleSessionDuration: 1800,

  subscription_Key: 'ff3d6df62e954b8bb97cf0849bab4df2',
  instrumentationKey: `50fc80b2-a648-4c97-907b-32c66515db02`,
  clientId: `1bae4003-6954-4a89-81e4-6413415f334b`, // DFPS B2C
  authority: `https://dfpsexdev.b2clogin.com/DFPSEXDEV.onmicrosoft.com/B2C_1_tare_susi`,
  clientCapabilities: [`CP1`], // This lets the resource server know that this client can handle claim challenges.

  dfpsDomainUrl: `${dfpsDomainUrl}`,
  oOCAdoptionFosterCareUrl: `${dfpsDomainUrl}/Adoption_and_Foster_Care`,
  oOCstaticContentUrl: `${dfpsDomainUrl}/Adoption_and_Foster_Care/common`,
  azureBlobStaticContentUrl: `https://testcontent-hke3arh7h6bcfqcp.z01.azurefd.net/Adoption_and_Foster_Care/common/app/header.asp`,
  redirectUri: "https://tareexternaldev.apps.dfps.texas.gov/application/TAREPublic/",
  tareMailBox: `TARE@dfps.texas.gov`,
  // APIs
  profilePageApi: `${apiMangerUrl}/ProfilePage/dev/api`,
  searchPageApi: `${apiMangerUrl}/SearchPage/dev/api`,
  profilePageApiAnonymous: `${apiMangerUrl}/ProfilePage/Anonymous/dev/api`,
  searchPageApiAnonymous: `${apiMangerUrl}/SearchPage/Anonymous/dev/api`,

  protectedResourceUrls: [
    // profilePageApi Protected resources

    // child Search Service
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetFamilySearchChildCharacteristics`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/AddDeleteBookMarksByUserName`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/CreateInquiry`,

    // Home Service
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetNotificationsByFamilyID`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetBookMarksByFamilyId`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetAllInquiriesForFamily`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetInquiryDetailsbyID`,

    // Family Service
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetFamilyById`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetFamilyProfileForUpdate`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/NewFamilyProfile`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/UpdateFamilyProfile`,
    `${apiMangerUrl}/ProfilePage/api/Profile/LinkFamilyWithAzureImage`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/NewFamilyMember`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/UpdateFamilyMember`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/DeleteFamilyMember`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/UpdateParentalExpectation`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetAdoptionInterestByFamilyID`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/AddUpdateAdoptionInterestByFamilyID`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetChildCharacteristicPreferences`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/UpdateChildCharacteristicPreferences`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/GetHomeAgencyByFamilyID`,
    `${apiMangerUrl}/ProfilePage/dev/api/Profile/AddUpdateHomeAgencyByFamilyID`,
    `${apiMangerUrl}/ProfilePage/dev/api/PublicLookup`,

    // Image Media Service
    // `${apiMangerUrl}/ProfilePage/dev/api/Profile/SiteContent`,

    // App Util Service
    `${apiMangerUrl}/ProfilePage/dev/api/Profile`,

    // searchPageAPI Protected resources
    `${apiMangerUrl}/SearchPage/dev/api/Search/Upload`,
    `${apiMangerUrl}/SearchPage/dev/api/Search/PublicLookup`,
    `${apiMangerUrl}/SearchPage/dev/api/Search/GetPublicChildById`,
    `${apiMangerUrl}/SearchPage/dev/api/Search/GetPublicSiblingById`,

    `${apiMangerUrl}/SearchPage/dev/api/Search/ChildPublicSearch`
  ]

};
