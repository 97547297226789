<app-profile-percent-success-msg [familyProfileDetails]="displayParentalExpectationsDetails"
    [successMsg]="familyProfileSuccessMsg"></app-profile-percent-success-msg>

<div #errors>
    <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
        [validationErrorsLength]="validationErrorsLength">
    </dfps-form-validation>
</div>

<div class="d-flex col-sm-12 justify-content-center">
    <p-progressSpinner *ngIf="!renderFamilyProfile"></p-progressSpinner>
</div>

<form [formGroup]="parentalExpectationsForm" (ngSubmit)="onSaveParentalExpectations()">
    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">Parental Expectations</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4 pt-3">
        <p class="col-sm-12 boldBody d-inline-flex mb-0"> We would like to learn more about your family's
            expectations and experience. This information will be used to match your family's strengths with
            children in Texas waiting to be matched with a forever family. </p>
    </div>
    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="employerFlexibility"
                class="col-sm-12 col-form-label col-form-label-sm requiredDisplay">Flexibility-Time: How
                flexible is your employer regarding your work absences, flexing work time to meet the needs of
                family/children?
            </label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-select _id="employerFlexibility" ariaLabel="Flexibility-Time: How flexible is your employer
            regarding your work absences, flexing work time to meet the needs of family/children?"
                formControlName="employerFlexibility" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['flexibilityTime']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="togethernessExpectations"
                class="col-sm-12 col-form-label col-form-label-sm requiredDisplay">Togetherness
                Expectations: What is the expectation for family group activities?</label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-select _id="togethernessExpectations"
                ariaLabel="Togetherness Expectations: What is the expectation for family group activities?"
                formControlName="togethernessExpectations" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['togethernessExpectations']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="childAcademicPerformance"
                class="col-sm-12 col-form-label col-form-label-sm requiredDisplay">Child's Academic Performance: What
                are your parental expectations of the level of the child's academic achievement-how far should a child
                go in school?</label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-select _id="childAcademicPerformance" ariaLabel="Child's Academic Performance: What are your parental expectations
             of the level of the child's academic achievement-how far should a child go in school?"
                formControlName="childAcademicPerformance" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['academicPerformance']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="schoolBehavior" class="col-sm-12 col-form-label col-form-label-sm requiredDisplay">School
                Behavior: What are your parental expectations regarding school problems related to child's behavior?
            </label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-select _id="schoolBehavior"
                ariaLabel="School Behavior: What are your parental expectations regarding school problems related to child's behavior? "
                formControlName="schoolBehavior" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['schoolBehavior']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="expressionEmotion" class="col-sm-12 col-form-label col-form-label-sm requiredDisplay">Expression
                of Emotion: How would you describe the way your family expresses affection/emotion?</label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-select _id="expressionEmotion"
                ariaLabel="Expression of Emotion: How would you describe the way your family expresses affection/emotion?"
                formControlName="expressionEmotion" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['expressionOfEmotion']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="physicalActivity" class="col-sm-12 col-form-label col-form-label-sm requiredDisplay">Physical
                Activity Level: What is the typical activity level of your family?</label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-select _id="physicalActivity"
                ariaLabel="Physical Activity Level: What is the typical activity level of your family?"
                formControlName="physicalActivity" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['physicalActivity']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-md-flex">
            <label for="ownPets" class="col-md-5 col-lg-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Do
                you own any pets?
            </label>
            <dfps-select _id="ownPets" ariaLabel="Do you own any pets?" formControlName="ownPets" aria-required="true"
                [dropDownValues]="parentalExpectationsLookup$ ? parentalExpectationsLookup$['ownPets']:[]">
            </dfps-select>
        </div>
    </div>

    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">Family Description </h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4 pt-3">
        <p class="col-sm-12 boldBody d-inline-flex mb-0"> Please describe your parenting experience and any
            special childcare skills or experiences you have parenting children with developmental, medical,
            physical, or emotional special needs. Please include how this child or sibling group will fit into your
            family and become part of your family. </p>
    </div>

    <div class="row pl-5 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="familyDescription" class="col-xl-2 col-form-label requiredDisplay">About Your
                Family</label>
        </div>
        <div class="col-sm-12 d-flex">
            <dfps-textarea _id="familyDescription" class="col-sm-12 p-0" formControlName="familyDescription"
                name="familyDescription" maxLength="4000" aria-required="true" ariaLabel="About Your Family"
                (focusout)="onTrimSpace('familyDescription')"></dfps-textarea>
        </div>
    </div>

    <div class="row pl-4">
        <div class="d-flex col-sm-12 pt-3 justify-content-end">
            <dfps-button type="submit" size="small" label="Save Changes" _id="saveParentalExpectations"
                [disabled]="requestProcessing"></dfps-button>
        </div>
    </div>
</form>