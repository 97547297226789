import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChildSearchService } from '../../services/child.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { DataShareService } from '@shared/services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import { AppUtilService } from '@shared/services/app-util.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-sibling-profile',
  templateUrl: './sibling-profile.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    "../../../../../.././node_modules/primeicons/primeicons.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/css/bootstrap-glyphicons.css',
    './sibling-profile.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SiblingProfileComponent implements OnInit {

  idSibling: number;
  siblingProfileResponse: any;
  renderPage: boolean;
  isUserLoggedIn: boolean;
  familyProfileInfo: any;
  groupedMedia: any;
  activeLangSpanish$: Observable<boolean>;

  constructor(
    private toasterService: ToastrService,
    private childService: ChildSearchService,
    private route: ActivatedRoute,
    private authService: MsalService,
    private shareService: DataShareService,
    private appUtilService: AppUtilService,
    private router: Router,) { }

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => this.activeLangSpanish$ = of(value));

    this.route.queryParamMap.subscribe(queryParam => {
      this.idSibling = +queryParam.get('id');
    });
    this.familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');
    this.childService.getTAREProfile(this.isUserLoggedIn, this.idSibling, this.isUserLoggedIn ? this.familyProfileInfo.familyId : null, true).subscribe((res) => {
      this.siblingProfileResponse = res;
      if (this.siblingProfileResponse?.allMedia?.length > 0) {
        this.siblingProfileResponse?.allMedia.sort((a, b) => (a.galleryName < b.galleryName ? -1 : 1));
        this.groupedMedia = this.appUtilService.groupBy(this.siblingProfileResponse?.allMedia, "galleryName");
      }
    },
      error => {
        this.toasterService.error(error, 'getTAREProfile Error');
      });
  }

  inquirySibling() {
    this.router.navigate(['/child/childSearch/inquiry'], { queryParams: { idSibling: this.idSibling } });
  }

  toggleBookmark() {
    this.siblingProfileResponse.isUserBookmark = !this.siblingProfileResponse.isUserBookmark;
    this.childService.addRemoveBookmark(this.siblingProfileResponse.siblingGroupMembers[0].idChild, this.familyProfileInfo.familyId, this.siblingProfileResponse.isUserBookmark, true).subscribe((res) => {
    },
      error => {
        this.siblingProfileResponse.isUserBookmark = !this.siblingProfileResponse.isUserBookmark;
        this.toasterService.error(error, 'addRemoveBookmark Error');
      });
  }

}