import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DfpsCheckBox,
  DfpsCollapsableSection,
  DfpsConfirm,
  DfpsDatePicker,
  DfpsDirtyCheck,
  DfpsInput,
  DfpsReportLaunch,
  DfpsRadioButton,
  DfpsUnauthorizedComponent,
  errorReducer,
  httpSuccessResponseReducer,
  dirtyCheckReducer,
  DfpsMultiSelect,
  DfpsNarrative,
  CurrencyInputModule
} from 'dfps-web-lib';
import {
  ApiService,
  AuthService,
  DfpsAddress,
  DfpsAddressValidation,
  DfpsAddressValidator,
  DfpsButton,
  DfpsDataTable,
  DfpsDataTableLazyload,
  DfpsDataTable1,
  DfpsDataTableLazyload1,
  DfpsFooter,
  DfpsFormLaunch,
  DfpsFormValidation,
  DfpsGlobalMessage,
  DfpsHeader,
  DfpsPrimaryNavigation,
  DfpsProgressBar,
  DfpsSecondaryNavigation,
  DfpsTernaryNavigation,
  DfpsSelect,
  DfpsSessionTimeout,
  DfpsUnauthorized,
  DfpsUnderconstruction,
  ENVIRONMENT_SETTINGS,
  GlobalErrorHandler,
  GlobalMessageServcie,
  HttpErrorResponseInterceptor,
  HttpRequestInterceptor,
  HttpSuccessResponseInterceptor,
  LoaderService,
  LogService,
  NavigationService,
  RedirectService,
  ReportService,
  VALIDATOR_ERRORS,
  DfpsTextarea,
  DfpsTime

} from 'dfps-web-lib';
import { ProgressSpinnerModule }
  from 'primeng/progressspinner';
import { environment } from 'environments/environment';
import { AccordionComponent, AccordionModule, AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { dfpsValidatorErrors } from '../messages/validator-errors';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastrModule } from 'ngx-toastr';
import { AppInsightMonitoringService } from './modules/shared/services/AppInsightMonitoringService';
import { ErrorHandlerService } from './modules/shared/services/ErrorHandlerService';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './MSALGuardConfiguration';
import { NoSanitizePipe } from './modules/shared/pipes/nosanitizerpipe';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { HomeModule } from './modules/home/home.module';
import { SharedModule } from '@shared/shared.module';
import { ChildModule } from './modules/child/child.module';
import { ChildSearchService } from './modules/child/services/child.service';
import { FamilyModule } from './modules/family/family.module';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import { ApiManagerInterceptor } from './ApiManagerInterceptor';
import { DataShareService } from '@shared/services/data-share.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { OfficeOfCommunicationModule } from './modules/office-of-communication/office-of-communication.module';
import { TranslocoRootModule } from './transloco-root.module';
import { CpaModule } from './modules/cpa-account/cpa-account.module';
import { InformationMeetingsModule } from './modules/information-meetings/information-meetings.module';

@NgModule({
  declarations: [
    AppComponent,
    NoSanitizePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OfficeOfCommunicationModule,
    AuthenticationModule,
    HomeModule,
    ChildModule,
    FamilyModule,
    SharedModule,
    CpaModule,
    InformationMeetingsModule,
    FileUploadModule,
    NgbModule,
    DfpsAddressValidator,
    ProgressSpinnerModule,
    DfpsButton,
    DfpsProgressBar,
    DfpsSessionTimeout,
    DfpsUnauthorized,
    DfpsUnderconstruction,
    DfpsSelect,
    DfpsFormLaunch,
    DfpsButton,
    DfpsDataTableLazyload,
    DfpsDataTableLazyload1,
    DfpsDataTable,
    DfpsDataTable1,
    DfpsTime,
    DfpsCollapsableSection,
    DfpsMultiSelect,
    DfpsTextarea,
    DfpsInput,
    DfpsRadioButton,
    DfpsCheckBox,
    FormsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    DfpsHeader,
    DfpsFooter,
    DfpsDatePicker,
    DfpsPrimaryNavigation,
    DfpsSecondaryNavigation,
    DfpsUnderconstruction,
    HttpClientModule,
    DfpsAddress,
    RadioButtonModule,
    TableModule,
    DataViewModule,
    StoreModule.forRoot({
      error: errorReducer,
      response: httpSuccessResponseReducer,
      dirtyCheck: dirtyCheckReducer
    }),
    DfpsFormValidation,
    DfpsReportLaunch,
    DfpsNarrative,
    DfpsTernaryNavigation,
    CurrencyInputModule,
    ToastrModule.forRoot({
    }),
    AppRoutingModule,
    TranslocoRootModule
  ],
  providers: [
    FormBuilder,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: ENVIRONMENT_SETTINGS, useValue: environment },
    { provide: VALIDATOR_ERRORS, useValue: dfpsValidatorErrors },
    { provide: DatePipe },
    { provide: MsalInterceptor },
    BsModalRef,
    GlobalMessageServcie,
    ApiService,
    AccordionComponent,
    AccordionPanelComponent,
    ToastrService,
    AccordionModule,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiManagerInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ChildSearchService,
    DatePipe,
    NavigationService,
    DataShareService,
    AppUtilService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    DfpsSessionTimeout
  ],
  exports:
    [
      DfpsButton,
      DfpsInput,
      DfpsAddressValidator,
      AccordionModule,
      DfpsAddress],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent]
})
export class AppModule {
}
