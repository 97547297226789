import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { AuthTareService } from '@shared/services/auth-tare.service';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.css']
})
export class LogonComponent implements OnInit {

  subs: Subscription[] = [];
  isUserLoggedIn: boolean;

  constructor(
    private authTareService: AuthTareService) {
  }

  ngOnInit(): void {
    this.authTareService.userLoginFlow();
  }

  login() {
    this.authTareService.login();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}
