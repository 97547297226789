import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InformationMeetingsService } from '../services/information-meetings.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-informaton-meetings',
  templateUrl: './information-meetings.component.html',
  styleUrls: ['./information-meetings.component.css']
})
export class InformationMeetingsComponent implements OnInit {

  meetingsInfo: any;
  infoMeetingsForm: FormGroup
  regionLookup: any;
  countiesList: any;
  scheduledMeetingsInfo: any;
  selectedRegion: string;
  attachments: any;
  scheduledInfoTotalRecords: number;
  counties: any;

  constructor(private formBuilder: FormBuilder,
    private InformationMeetingsService: InformationMeetingsService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.InformationMeetingsService.schedulingPageLookups().then(data => {
      this.InformationMeetingsService.regionLookup = data;
      this.regionLookup = data;
    });
  }

  private createForm() {
    this.infoMeetingsForm = this.formBuilder.group({
      region: ['']
    });
  }

  onRegionChange() {
    const selectedRegion = this.infoMeetingsForm.get('region').value;
    this.InformationMeetingsService.schedulingCountiesByRegion(selectedRegion).subscribe(countiesData => {
      this.counties = countiesData;
      this.countiesList = this.counties.join(", ")
    });

    this.InformationMeetingsService.getScheduledMeetingsInfobyRegion(selectedRegion).subscribe(scheduledInfo => {
      this.scheduledMeetingsInfo = scheduledInfo;
      this.scheduledInfoTotalRecords = this.scheduledMeetingsInfo?.length;
    });

    this.regionLookup?.regions.forEach(regionInfo => { if (regionInfo.code === selectedRegion) { this.selectedRegion = regionInfo.decode; } });
  }

  downloadFile(attachment) {
    const data = attachment.txtBlobUrl;
    saveAs(data, attachment.nmFile);
  }

}
