import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationMeetingsComponent } from './meetings/information-meetings.component';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DfpsSelect } from 'dfps-web-lib';
import { InfoMeetingsRoutingModule } from './information-meetings-routing.module';

@NgModule({
    declarations: [
        InformationMeetingsComponent
    ],
    imports: [
        CommonModule,
        TableModule,
        ReactiveFormsModule,
        FormsModule,
        DfpsSelect,
        InfoMeetingsRoutingModule
    ]
})
export class InformationMeetingsModule { }
