import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teritiary-nav',
  templateUrl: './teritiary-nav.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './teritiary-nav.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TeritiaryNavComponent implements OnInit {

  @Input() showTeritiaryNavFor: string;
  profileId: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.profileId = +queryParams.get('id');
    });
  }

}
