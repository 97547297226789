import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus, AccountInfo, InteractionRequiredAuthError, RedirectRequest } from '@azure/msal-browser';
import { protectedResources } from '@shared/config/auth-configb2c';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { filter } from 'rxjs/operators';
import { DataShareService } from './data-share.service';
import { AppUtilService } from './app-util.service';
import { AppLanguages } from '@shared/enums/app-languages';

@Injectable({
  providedIn: 'root'
})
export class AuthTareService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadCastService: MsalBroadcastService,
    private router: Router,
    private shareService: DataShareService,
    private appUtilService: AppUtilService,
    private authService: MsalService,) { }

  public get RoleFamily() { return UserRoles.Family; }
  public get RoleCPA() { return UserRoles.CPA; }
  public accountInfo: any = null;
  isUserLoggedIn: boolean;

  hasValidRole(role) {
    return this.userAuthorities()?.some(x => x === role);
  }

  public userAuthorities() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      const authorities = this.msalService?.instance?.getAllAccounts()[0]?.idTokenClaims.extension_Roles as string;
      return [authorities];
    }
  }

  isLoggedAsValidRole() {
    return Object.values(UserRoles).some(item => this.userAuthorities()?.includes(item));
  }

  userLoginFlow(): void {
    this.isUserLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
    if (this.isUserLoggedIn) {
      this.userLoginCheck();
    } else {
      this.msalBroadCastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);
          this.userLoginCheck();
        });
    }
  }

  userLoginCheck(): void {
    if (this.isLoggedAsValidRole()) {
      this.routeToUserHomePage();
    } else {
      this.router.navigate([`/accessError`]);
    }
  }

  routeToUserHomePage(): void {
    this.isUserLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
    if (this.isUserLoggedIn) {
      if (this.shareService.getDataFromMap('familyProfileInfo')?.familyId) {
        this.router.navigate([this.hasValidRole(UserRoles.CPA) ? '/child/childSearch' : '/home']);
      } else {
        this.router.navigate([this.hasValidRole(UserRoles.CPA) ? '/cpaAccountRegister' : '/createAccount']);
      }
    }
  }

  login() {
    let authRequestConfig: RedirectRequest;
    if (this.msalGuardConfig.authRequest) {
      authRequestConfig = { ...this.msalGuardConfig.authRequest } as RedirectRequest
    }
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        authRequestConfig.extraQueryParameters = { "ui_locales": AppLanguages.Spanish };
      } else {
        authRequestConfig.extraQueryParameters = { "ui_locales": AppLanguages.English };
      }
    });

    this.msalGuardConfig.authRequest ? this.authService.loginRedirect({ ...authRequestConfig } as RedirectRequest) :
      this.authService.loginRedirect();
  }

}
