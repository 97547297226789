import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DfpsFormValidationDirective, DirtyCheck, FormValidationError } from 'dfps-web-lib';
import { ChildSearchService } from '../../services/child.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from '@shared/services/data-share.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/css/bootstrap-glyphicons.css',
    './inquiry.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class InquiryComponent extends DfpsFormValidationDirective implements OnInit {

  idChild: any;
  idSibling: any;
  familyProfileInfo: any;
  isUserLoggedIn: boolean;
  inquiryForm: FormGroup;
  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  prePopulateInquiryData: any;
  requestProcessing: Boolean;
  childProfileResponse: any;
  displayprofileSetupMsg: boolean;
  dspRelativeRelation: boolean;
  inquiryLookup$: Observable<any>;
  relativeDropdown: any[];
  fictiveKinDropdown: any[];

  constructor(
    public store: Store<{ dirtyCheck: DirtyCheck }>,
    private childService: ChildSearchService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private shareService: DataShareService,
    private appUtilService: AppUtilService) {
    super(store);
  }

  ngOnInit(): void {
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.childService.getInquiryPageLookupsES().then(data => {
          this.inquiryLookup$ = data;
          this.filterRelationshipLookup();

        })
      } else {
        this.childService.getInquiryPageLookups().then(data => {
          this.childService.inquiryPageLookups = data;
          this.inquiryLookup$ = data;
          this.filterRelationshipLookup();
        });
      }
    });

    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    this.route.queryParamMap.subscribe(queryParam => {
      this.idChild = queryParam.get('idChild') ? +queryParam.get('idChild') : queryParam.get('idChild');
      this.idSibling = queryParam.get('idSibling') ? +queryParam.get('idSibling') : queryParam.get('idSibling');
    });
    this.familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');

    this.childService.getTAREProfile(this.isUserLoggedIn, this.idChild ? this.idChild : this.idSibling, this.isUserLoggedIn ? this.familyProfileInfo.familyId : null, this.idSibling ? true : false).subscribe((res) => {
      this.childProfileResponse = res;
    },
      error => {
        this.toasterService.error(error, 'getChildProfile Error');
      });
    this.createForm();
    this.appUtilService.familyProfileInfo$ = null;
    this.shareService.removeDataFromMap('familyProfileInfo');
    this.appUtilService.getFamilyProfileInfo().then(res => { this.familyProfileInfo = res });
  }

  filterRelationshipLookup() {
    if (this.inquiryLookup$ && this.inquiryLookup$['relationship']) {
      this.relativeDropdown = [];
      this.fictiveKinDropdown = [];
      this.inquiryLookup$['relationship'].forEach((obj) => {
        if (obj.code === 'FK') {
          this.fictiveKinDropdown.push(obj);
        }
        if (obj.code !== 'FK') {
          this.relativeDropdown.push(obj);
        }
      });
    }
  }

  createForm() {
    this.inquiryForm = this.formBuilder.group({
      relatedToChildOrSiblingGroup: ['N', Validators.required],
      childId: [this.idChild],
      siblingGroupId: [this.idSibling],
      familyId: [this.familyProfileInfo ? this.familyProfileInfo.familyId : null]
    });
  }

  onRelatedChange() {
    if (this.inquiryForm.value.relatedToChildOrSiblingGroup === 'Y') {
      this.inquiryForm = this.formBuilder.group({
        relatedToChildOrSiblingGroup: ['Y', Validators.required],
        relativeType: ['10', Validators.required],
        relationship: ['FK', Validators.required],
        relationshipDescription: ['', Validators.required],
        childId: [this.idChild],
        siblingGroupId: [this.idSibling],
        familyId: [this.familyProfileInfo ? this.familyProfileInfo.familyId : null]
      });
      this.dspRelativeRelation = true;
    } else {
      this.validationErrors = [];
      this.validationErrorsLength = 0;
      this.createForm();
      this.dspRelativeRelation = false;
    }
    this.onRelativeTypeChange();
    this.inquiryForm.updateValueAndValidity();
  }

  onRelativeTypeChange() {
    if (this.inquiryForm.value.relativeType === '10') {
      this.inquiryLookup$['relationship'] = this.fictiveKinDropdown;
      this.inquiryForm.get('relationship').setValue('FK');
    } else if (this.inquiryForm.value.relativeType === '5') {
      this.inquiryLookup$['relationship'] = this.relativeDropdown;
      this.inquiryForm.get('relationship').setValue('AU');
    }
  }

  onTrimSpace(formControl) {
    this.appUtilService.onTrimSpace(this.inquiryForm, formControl);
  }

  completeProfile(completeProfile) {
    completeProfile ? this.router.navigate(['/familyProfile/familyInfo']) : this.displayprofileSetupMsg = false;
  }

  submitInquiry() {
    if (this.inquiryForm.value.relatedToChildOrSiblingGroup === 'N' && this.familyProfileInfo.profilePercent < 100) {
      this.displayprofileSetupMsg = true;
    } else if (this.validateFormGroup(this.inquiryForm)) {
      this.requestProcessing = true;
      this.inquiryForm.value.relatedToChildOrSiblingGroup = (this.inquiryForm.value.relatedToChildOrSiblingGroup === 'Y') ? true : false;
      this.childService.submitInquiry(this.inquiryForm.value).subscribe((res) => {
        if (res) {
          this.requestProcessing = false;
          this.ngOnInit();
        }
      }, error => {
        this.requestProcessing = false;
        this.toasterService.error(error, 'submitInquiry Error');
      });
    }
  }
}
