<div class="tab-content paddingBottom15" role="tabpanel">
    <ul class="nav nav-pills thirdLevelP" role="tablist">
        <ng-template [ngIf]="showTeritiaryNavFor==='childCharPref'">
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="nav-pills-behavioral-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/behavioral"
                    aria-controls="nav-pills-behavioral" routerLinkActive="active" #behavioralRla="routerLinkActive"
                    [attr.aria-selected]="behavioralRla.isActive ? true : false"> Behavioral </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-emotional-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/emotional"
                    aria-controls="nav-pills-emotional" routerLinkActive="active" #emotionalRla="routerLinkActive"
                    [attr.aria-selected]="emotionalRla.isActive ? true : false"> Emotional </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-physical-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/physical"
                    aria-controls="nav-pills-physical" routerLinkActive="active" #physicalRla="routerLinkActive"
                    [attr.aria-selected]="physicalRla.isActive ? true : false"> Physical </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-medical-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/medical"
                    aria-controls="nav-pills-medical" routerLinkActive="active" #medicalRla="routerLinkActive"
                    [attr.aria-selected]="medicalRla.isActive ? true : false"> Medical </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-developmental-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/developmental"
                    aria-controls="nav-pills-developmental" routerLinkActive="active"
                    #developmentalRla="routerLinkActive"
                    [attr.aria-selected]="developmentalRla.isActive ? true : false"> Developmental </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-learning-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/learning"
                    aria-controls="nav-pills-learning" routerLinkActive="active" #learningRla="routerLinkActive"
                    [attr.aria-selected]="learningRla.isActive ? true : false"> Learning </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-riskFactor-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/riskFactor"
                    aria-controls="nav-pills-riskFactor" routerLinkActive="active" #riskFactorRla="routerLinkActive"
                    [attr.aria-selected]="riskFactorRla.isActive ? true : false"> Risk Factor </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-interestHobbies-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/interestHobbies"
                    aria-controls="nav-pills-interestHobbies" routerLinkActive="active"
                    #interestHobbiesRla="routerLinkActive"
                    [attr.aria-selected]="interestHobbiesRla.isActive ? true : false"> Interest/Hobbies </a>
            </li>
        </ng-template>
        <ng-template [ngIf]="showTeritiaryNavFor==='cpaCharPref'">
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="nav-pills-characteristics-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/child/childSearch/cpaSpecialNeeds/characteristics" aria-controls="nav-pills-behavioral"
                    routerLinkActive="active" #characteristicsRla="routerLinkActive" [queryParams]="{id:profileId}"
                    [attr.aria-selected]="characteristicsRla.isActive ? true : false"> Characteristics </a>
            </li>
            <li class="nav-item ml-2" role="presentation">
                <a class="nav-link" id="nav-pills-interestHobbies-tab" data-toggle="pill" role="tab"
                    (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/child/childSearch/cpaSpecialNeeds/interestHobbies"
                    aria-controls="nav-pills-interestHobbies" routerLinkActive="active"
                    #interestHobbiesRla="routerLinkActive" [queryParams]="{id:profileId}"
                    [attr.aria-selected]="interestHobbiesRla.isActive ? true : false"> Interest/Hobbies </a>
            </li>
        </ng-template>
    </ul>
</div>