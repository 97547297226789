<app-profile-percent-success-msg *ngIf="displayFamilyProfileDetails?.familyId"
    [familyProfileDetails]="displayFamilyProfileDetails" [successMsg]="familyProfileSuccessMsg">
</app-profile-percent-success-msg>

<div #errors>
    <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
        [validationErrorsLength]="validationErrorsLength">
    </dfps-form-validation>
</div>

<div class="d-flex col-sm-12 justify-content-center">
    <p-progressSpinner *ngIf="!renderFamilyProfile"></p-progressSpinner>
</div>

<form [formGroup]="familyProfileForm" (ngSubmit)="onSaveFamilyProfile()">
    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">{{displayFamilyProfileDetails?.familyId? "Family Information": "Create User"}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-5">
        <div class="col-lg-6">
            <div class="row pt-3">
                <p class="col-sm-12 boldBody d-inline-flex pl-0 mb-0"><strong>Please complete the information below
                        for your family's primary contact:</strong></p>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12 d-flex">
                    <label for="firstName"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">First
                        Name:</label>
                    <dfps-input class="notranslate" _id="firstName" ariaLabel="First Name" formControlName="firstName"
                        aria-required="true" maxlength="22">
                    </dfps-input>
                </div>
            </div>
            <div class="row pt-3" *ngIf="displayFamilyProfileDetails?.familyId">
                <div class="col-sm-12 d-flex">
                    <label for="middleName" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Middle Name:
                    </label>
                    <dfps-input class="notranslate" _id="middleName" ariaLabel="Middle Name"
                        formControlName="middleName" maxlength="22">
                    </dfps-input>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12  d-flex">
                    <label for="lastName"
                        class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Last
                        Name:</label>
                    <dfps-input class="notranslate" _id="lastName" ariaLabel="Last Name" formControlName="lastName"
                        aria-required="true" maxlength="22">
                    </dfps-input>
                </div>
            </div>
            <ng-template [ngIf]="displayFamilyProfileDetails?.familyId">
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="suffix" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Suffix:
                        </label>
                        <dfps-select _id="suffix" ariaLabel="Suffix" formControlName="suffix"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['suffix']:[]">
                        </dfps-select>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="dateOfBirth"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Date of
                            Birth:
                        </label>
                        <dfps-date-picker _id="dateOfBirth" formControlName="dateOfBirth" name="Date of Birth"
                            aria-required="true" ariaLabel="Date of Birth" [maxDate]="maxDate"
                            [minDate]="minDate"></dfps-date-picker>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="gender" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Gender:</label>
                        <dfps-select _id="gender" ariaLabel="Gender" formControlName="gender"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['gender']:[]">
                        </dfps-select>
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="col-lg-6 d-flex" *ngIf="displayFamilyProfileDetails?.familyId">
            <div class="row pt-3">
                <div class="d-flex pl-4 col-sm-12 justify-content-end width190">
                    <img *ngIf="displayFamilyProfileDetails?.familyImageID" class="img-fluid searchResultsImageSize"
                        [src]="displayFamilyProfileDetails?.familyImageID"
                        [alt]="'Portrait of ' + displayFamilyProfileDetails?.firstName + '' + displayFamilyProfileDetails?.lastName +' Family Whose Family ID ' + displayFamilyProfileDetails?.familyId">
                </div>
                <div class="d-flex pl-4 col-sm-11 pb-5 justify-content-end">
                    <dfps-button add-button size="extraSmall"
                        label="{{displayFamilyProfileDetails?.familyImageID ? 'Change Photo' : 'Add Photo'}}"
                        _id="uploadFamilyPhoto" (click)="uploadFiles()"></dfps-button>
                </div>
            </div>
        </div>
    </div>

    <ng-template [ngIf]="displayFamilyProfileDetails?.familyId">
        <div class="row pl-5">
            <div class="d-flex col-sm-12 pt-2">
                <dfps-multi-select id="race" class="requiredDisplay d-flex"
                    [multiSelectValues]="familyProfileLookup$ ? familyProfileLookup$['race']:[]" formControlName="race"
                    [preSelectedValues]="preSelectedRaceValues" legendTitle="Race" aria-required="true" inputName="race"
                    ariaLabel="Race" (selectedValues)="selectedRaceValues('race', $event)">
                </dfps-multi-select>
            </div>
        </div>

        <div class="row pl-5">
            <div class="col-lg-6">
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="ethnicity"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Ethnicity:</label>
                        <dfps-select _id="ethnicity" ariaLabel="Ethnicity" formControlName="ethnicity"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['ethnicity']:[]">
                        </dfps-select>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 d-flex">
                <div class="row pt-3"> </div>
            </div>
        </div>
        <div class="row pl-5">
            <div class="col-lg-6">
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay"
                            for="maritalStatus">Marital Status:</label>
                        <dfps-select _id="maritalStatus" ariaLabel="Marital Status" formControlName="maritalStatus"
                            aria-required="true"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['maritalStatus']:[]"
                            (change)="maritalStatusChange()">
                        </dfps-select>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 d-flex"
                *ngIf="familyProfileForm.get('maritalStatus').value!='' && familyProfileForm.get('maritalStatus').value!=null && familyProfileForm.get('maritalStatus').value==='DI'">
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay"
                            for="dateOfDivorce">Date of Divorce:</label>
                        <dfps-date-picker _id="dateOfDivorce" formControlName="dateOfDivorce" name="Date of Divorce"
                            aria-required="true" ariaLabel="Date of Divorce" [maxDate]="maxDate"
                            [minDate]="minDate"></dfps-date-picker>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 d-flex"
                *ngIf="familyProfileForm.get('maritalStatus').value!='' && familyProfileForm.get('maritalStatus').value!=null && familyProfileForm.get('maritalStatus').value==='MA'">
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay"
                            for="dateOfMarriage">Date of Marriage:</label>
                        <dfps-date-picker _id="dateOfMarriage" formControlName="dateOfMarriage" name="Date of Marriage"
                            aria-required="true" ariaLabel="Date of Marriage" [maxDate]="maxDate"
                            [minDate]="minDate"></dfps-date-picker>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template
        [ngIf]="familyProfileForm.get('maritalStatus').value!='' && familyProfileForm.get('maritalStatus').value!=null && familyProfileForm.get('maritalStatus').value==='MA'">
        <div class=" row pl-3 pt-3">
            <h2 class="col-sm-12">Spouse Information</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>

        <div class="row pl-5">
            <div class="col-sm-6">
                <div class="row pt-3">
                    <p class="col-sm-12 boldBody d-inline-flex pl-0 mb-0">
                        <strong>Please complete the information below for your spouse:</strong>
                    </p>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="spouseFirstName"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">First Name:
                        </label>
                        <dfps-input class="notranslate" _id="spouseFirstName" ariaLabel="Spouse First Name"
                            formControlName="spouseFirstName" aria-required="true" maxlength="22">
                        </dfps-input>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="spouseMiddleName" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Middle
                            Name:
                        </label>
                        <dfps-input class="notranslate" _id="spouseMiddleName" ariaLabel="Spouse Middle Name"
                            formControlName="spouseMiddleName" maxlength="22">
                        </dfps-input>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12  d-flex">
                        <label for="spouseLastName"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Last Name:
                        </label>
                        <dfps-input class="notranslate" _id="spouseLastName" ariaLabel="Spouse Last Name"
                            formControlName="spouseLastName" aria-required="true" maxlength="22">
                        </dfps-input>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="spouseSuffix" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Suffix:
                        </label>
                        <dfps-select _id="spouseSuffix" ariaLabel="Spouse Suffix" formControlName="spouseSuffix"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['suffix']:[]">
                        </dfps-select>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="spouseDateOfBirth"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Date of
                            Birth:
                        </label>
                        <dfps-date-picker _id="spouseDateOfBirth" formControlName="spouseDateOfBirth"
                            name="Spouse Date of Birth" aria-required="true" ariaLabel="Spouse Date of Birth"
                            [maxDate]="maxDate" [minDate]="minDate">
                        </dfps-date-picker>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="spouseGender"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Gender:</label>
                        <dfps-select _id="spouseGender" ariaLabel="spouse Gender" formControlName="spouseGender"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['gender']:[]">
                        </dfps-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pl-5">
            <div class="d-flex col-sm-12 pt-2">
                <dfps-multi-select id="spouseRace" class="requiredDisplay d-flex"
                    [multiSelectValues]="familyProfileLookup$ ? familyProfileLookup$['race']:[]"
                    formControlName="spouseRace" legendTitle="Spouse Race" aria-required="true" inputName="spouseRace"
                    ariaLabel="Spouse Race" [preSelectedValues]="preSelectedSpouseRaceValues"
                    (selectedValues)="selectedRaceValues('spouseRace', $event)">
                </dfps-multi-select>
            </div>
        </div>

        <div class="row pl-5">
            <div class="col-sm-6">
                <div class="row pt-3">
                    <div class="col-sm-12 d-flex">
                        <label for="spouseEthnicity"
                            class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Ethnicity:</label>
                        <dfps-select _id="spouseEthnicity" ariaLabel="Spouse Ethnicity"
                            formControlName="spouseEthnicity"
                            [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['ethnicity']:[]">
                        </dfps-select>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div class=" row pl-3 pt-3">
        <h2 class="col-sm-12">Contact Information</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>

    <div class="row pl-5">
        <div class="d-flex col-lg-6 pt-3">
            <label for="emailAddress" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Email
                Address:</label>
            <dfps-input _id="emailAddress" ariaLabel="Email Address" formControlName="emailAddress" aria-required="true"
                maxlength="128">
            </dfps-input>
        </div>
        <div class="d-flex col-lg-6 pt-3">
            <label for="primaryPhone" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Phone
                Number:
            </label>
            <dfps-input _id="primaryPhone" ariaLabel="Phone Number" formControlName="primaryPhone" aria-required="true"
                maxlength="10">
            </dfps-input>
        </div>
    </div>
    <div class="row pl-5">
        <div class="d-flex col-lg-6 pt-3">
            <label for="secondaryPhone" class="col-sm-4 col-xl-3 col-form-label col-form-label-sm">Secondary Phone:
            </label>
            <dfps-input _id="secondaryPhone" ariaLabel="Secondary Phone" formControlName="secondaryPhone"
                maxlength="10">
            </dfps-input>
        </div>
    </div>

    <div class="row pl-5">
        <div class="col-lg-6 pt-3">
            <div class="row">
                <p class="boldBody h6"><strong>Mailing Address:</strong></p>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12 d-flex">
                    <label for="mailingAddress0"
                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Address 1:</label>
                    <dfps-input _id="mailingAddress0" ariaLabel="Mailing Address 1" aria-required="true"
                        formControlName="mailingAddress0" maxlength="30">
                    </dfps-input>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12 d-flex">
                    <label for="mailingAddress1" class="col-sm-3 col-form-label col-form-label-sm">Address
                        2:</label>
                    <dfps-input _id="mailingAddress1" ariaLabel="Mailing Address 2" formControlName="mailingAddress1"
                        maxlength="30">
                    </dfps-input>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12  d-flex">
                    <label for="mailingCity"
                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">City:</label>
                    <dfps-input _id="mailingCity" ariaLabel="Mailing City" formControlName="mailingCity"
                        aria-required="true" maxlength="20">
                    </dfps-input>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12 d-flex">
                    <label for="mailingState"
                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">State:</label>
                    <dfps-select _id="mailingState" ariaLabel="Mailing State" formControlName="mailingState"
                        aria-required="true" [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['state']:[]"
                        (change)="changeState()">
                    </dfps-select>
                </div>
            </div>
            <div class="row" *ngIf="!displayFamilyProfileDetails?.familyId">
                <div class="col-sm-12 pt-3">
                    If you are not a Texas resident, please visit the
                    <strong> <a
                            href="https://www.dfps.texas.gov/Adoption_and_Foster_Care/About_TARE/Helpful_Resources/websites.asp">
                            Out of State Adoption page Information</a></strong>
                    to get more information on being approved to adopt in your state.
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12 d-flex">
                    <label for="mailingCounty"
                        class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">County:</label>
                    <dfps-select _id="mailingCounty" ariaLabel="Mailing County" formControlName="mailingCounty"
                        aria-required="true"
                        [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['counties']:[]"
                        (change)="changeCounty()">
                    </dfps-select>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-sm-12 d-flex">
                    <label for="mailingZip" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Zip
                        :</label>
                    <dfps-input _id="mailingZip" ariaLabel="Mailing Zip" formControlName="mailingZip"
                        aria-required="true" maxlength="5" (paste)="pasteNumberOnly($event)"
                        (keypress)="validateNumberOnly($event)">
                    </dfps-input>
                </div>
            </div>
        </div>

        <div class="col-lg-6 pt-3">
            <div class="row">
                <p class="boldBody h6"><strong>Physical Address:</strong></p>
                <div class="col-sm-12 pt-2 d-flex">
                    <dfps-checkbox _id="sameMailingAddress" ariaLabel="Same as Mailing Address"
                        label="Same as Mailing Address" inputName="sameMailingAddress" name="sameMailingAddress"
                        [formControl]="familyProfileForm.controls['sameMailingAddress']" (click)="sameMailingAddress()">
                    </dfps-checkbox>
                </div>
            </div>
            <div class="row" *ngIf="!familyProfileForm.controls['sameMailingAddress'].value">
                <div class="col-sm-12">
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="physicalAddress0"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Address 1:</label>
                            <dfps-input _id="physicalAddress0" ariaLabel="physical Address 1" aria-required="true"
                                formControlName="physicalAddress0" maxlength="30">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 pt-3 d-flex">
                            <label for="physicalAddress1" class="col-sm-3 col-form-label col-form-label-sm">Address
                                2:</label>
                            <dfps-input _id="physicalAddress1" ariaLabel="physical Address 2"
                                formControlName="physicalAddress1" maxlength="30">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 pt-3 d-flex">
                            <label for="physicalCity"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">City:</label>
                            <dfps-input _id="physicalCity" ariaLabel="physical City" formControlName="physicalCity"
                                aria-required="true" maxlength="20">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 pt-3 d-flex">
                            <label for="physicalState"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay"> State:</label>
                            <dfps-select _id="physicalState" ariaLabel="physical State" aria-required="true"
                                formControlName="physicalState"
                                [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['state']:[]"
                                (change)="changeState()">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 pt-3 d-flex">
                            <label for="physicalCounty"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">County:</label>
                            <dfps-select _id="physicalCounty" ariaLabel="Physical County" aria-required="true"
                                formControlName="physicalCounty"
                                [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['counties']:[]"
                                (change)="changeCounty()">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 pt-3 d-flex">
                            <label for="physicalZip"
                                class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Zip :</label>
                            <dfps-input _id="physicalZip" ariaLabel="physical Zip" formControlName="physicalZip"
                                aria-required="true" maxlength="5" (paste)="pasteNumberOnly($event)"
                                (keypress)="validateNumberOnly($event)">
                            </dfps-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row pl-3 pt-3">
        <h2 class="col-sm-12">Referral and Interest</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-5">
        <div class="d-flex col-sm-12 col-xl-6 pt-3">
            <label for="whoReferred" class="col-sm-6 col-form-label col-form-label-sm requiredDisplay">How
                were you referred to us?:</label>
            <dfps-select _id="whoReferred" ariaLabel="How were you referred to us?" formControlName="whoReferred"
                aria-required="true" [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['referrals']:[]"
                (change)="referralOther()">
            </dfps-select>
        </div>
        <div class="d-flex col-sm-12 col-xl-6 pt-3"
            *ngIf="familyProfileForm.get('whoReferred').value!='' && familyProfileForm.get('whoReferred').value!=null && familyProfileForm.get('whoReferred').value==='75'">
            <label for="referralOther" class="col-sm-6 col-form-label col-form-label-sm requiredDisplay">Referral
                Other:</label>
            <dfps-input _id="referralOther" ariaLabel="Referral Other" formControlName="referralOther"
                aria-required="true" maxlength="30">
            </dfps-input>
        </div>
    </div>
    <div class="row pl-5">
        <div class="d-flex col-sm-12 col-xl-6 pt-3">
            <label for="parentingInterest" class="col-sm-6 col-form-label col-form-label-sm requiredDisplay">Parenting
                Interest:</label>
            <dfps-select _id="parentingInterest" ariaLabel="Parenting Interest" formControlName="parentingInterest"
                aria-required="true" [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['interests']:[]">
            </dfps-select>
        </div>
    </div>


    <ng-template [ngIf]="displayFamilyProfileDetails?.familyId">
        <div class="row pl-3 pt-3">
            <h2 class="col-sm-12">Languages</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-5">
            <div class="d-flex col-sm-12 col-xl-6 pt-3">
                <label for="primaryLanguage"
                    class="col-sm-6 col-xl-5 col-form-label col-form-label-sm requiredDisplay">Primary
                    Language:</label>
                <dfps-select _id="primaryLanguage" ariaLabel="Primary Language" aria-required="true"
                    formControlName="primaryLanguage"
                    [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['languages']:[]"
                    (change)="primaryLanguageOther()">
                </dfps-select>
            </div>
            <div class="d-flex col-sm-12 col-xl-6 pt-3"
                *ngIf="familyProfileForm.get('primaryLanguage').value!='' && familyProfileForm.get('primaryLanguage').value!=null && familyProfileForm.get('primaryLanguage').value==='XX'">
                <label for="primaryLanguageOther"
                    class="col-sm-6 col-xl-5 col-form-label col-form-label-sm requiredDisplay">Other Primary
                    Language:</label>
                <dfps-input _id="primaryLanguageOther" ariaLabel="Other Primary Language"
                    formControlName="primaryLanguageOther" aria-required="true" maxlength="30">
                </dfps-input>
            </div>
        </div>
        <div class="row pl-5">
            <div class="d-flex col-sm-12 col-xl-6 pt-3">
                <label for="secondaryLanguage" class="col-sm-6 col-xl-5 col-form-label col-form-label-sm">Secondary
                    Language:</label>
                <dfps-select _id="secondaryLanguage" ariaLabel="Secondary Language" formControlName="secondaryLanguage"
                    [dropDownValues]="familyProfileLookup$ ? familyProfileLookup$['languages']:[]"
                    (change)="secondaryLanguageOther()">
                </dfps-select>
            </div>
            <div class="d-flex col-sm-12 col-xl-6 pt-3"
                *ngIf="familyProfileForm.get('secondaryLanguage').value!='' && familyProfileForm.get('secondaryLanguage').value!=null && familyProfileForm.get('secondaryLanguage').value==='XX'">
                <label for="secondaryLanguageOther"
                    class="col-sm-6 col-xl-5 col-form-label col-form-label-sm requiredDisplay">Other Secondary
                    Language:</label>
                <dfps-input _id="secondaryLanguageOther" ariaLabel="Other Secondary Language"
                    formControlName="secondaryLanguageOther" aria-required="true" maxlength="30">
                </dfps-input>
            </div>
        </div>
    </ng-template>


    <div class="row pl-5" *ngIf="displayFamilyProfileDetails?.familyId">
        <div class="d-flex col-sm-12 pt-3">
            <p class="boldBody mb-0">DFPS periodically sends emails to families registered on TARE when
                children who match the preferences in your profile are identified. These emails come from
                <a href="javascript:obfuscator('TXDFPS','public.govdelivery.com');">TXDFPS@public.govdelivery.com</a>.
            </p>
        </div>
        <div class="d-flex col-sm-12 pt-2">
            <dfps-checkbox _id="optOutOfEmails" ariaLabel="Opt-out of receiving emails from DFPS staff"
                label="Opt-out of receiving emails from DFPS staff" inputName="optOutOfEmails" name="optOutOfEmails"
                [formControl]="familyProfileForm.controls['optOutOfEmails']">
            </dfps-checkbox>
        </div>
    </div>

    <div class="row pl-5">
        <div class="d-flex col-sm-12 pt-3 justify-content-end">
            <dfps-button type="submit" size="small" label="Save Changes" _id="saveFamilyProfile"
                [disabled]="requestProcessing"></dfps-button>
        </div>
    </div>
</form>

<ng-template [ngIf]="displayFamilyProfileDetails?.familyId">
    <div class="row pl-3 pt-3">
        <h2 class="col-sm-12">Family Members</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>

    <div class="row pl-3 pt-3" id="addFamilyMembersTable">
        <div class="col-md-12 pl-4">
            <p-table #dt appAccessibilityPTableTare [columns]="familyMembersTableColumns"
                [value]="familyMembersTableData" [paginator]="true" [rows]="5"
                [totalRecords]="familyMembersTableTotalRecords" [first]="0" [alwaysShowPaginator]="false"
                [autoLayout]="false" class="dfps-data-table-container__ptable">
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup tabindex="-1" id="colGroupId">
                        <col class="column-width" style="width:160px" />
                        <col *ngFor="let col of columns" [style.width]="col.width+'px'" />
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="caption">
                    <span class="pi pi-filter pull-right"
                        [attr.aria-label]="!familyMembersTableFilterToggleInd ? 'Filter Toggle Off Click To Show Filters' : 'Filter Toggle On Click To Hide Filters'"
                        role="button" tabindex="0" (click)="filterTogglefamilyMembersTable(dt)"
                        (keypress)="filterTogglefamilyMembersTable(dt)"> <span class="sr-only">Filter</span>
                    </span>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Actions</th>
                        <th [pSortableColumn]="column.field" *ngFor="let column of columns">
                            {{column.header}}
                            <p-sortIcon [field]="column.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                            </p-sortIcon>
                        </th>
                    </tr>

                    <tr [hidden]=" !familyMembersTableFilterToggleInd">
                        <th><span class="sr-only">Thead for Column Filter</span></th>
                        <th *ngFor="let col of columns">
                            <span class="sr-only">Thead for Column Filters</span>
                            <input pInputText type="text" attr.aria-label={{getTableColumnAriaLabel(col.header)}}
                                (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"
                                [value]="dt.filters[col.field] ? $any(dt.filters[col.field])?.value : ''" />
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-familyMembersTableData let-columns="columns">
                    <tr>
                        <td>
                            <ng-template
                                [ngIf]="(familyMembersTableData?.relation !== 'SL' &&  familyMembersTableData?.relation !=='SP')">
                                <span class="pi pi-user-edit familyMemberEdit" role="button" tabindex="0"
                                    [attr.aria-label]="'Edit Family Member ' + familyMembersTableData?.childName"
                                    (click)="addUpdateDeleteFamilyMember('update', familyMembersTableData)"
                                    (keypress)="addUpdateDeleteFamilyMember('update', familyMembersTableData)">
                                    <span style="font-size:14px"> Edit |</span>
                                </span>
                                <span class="pi pi-trash ml-2" role="button" tabindex="0"
                                    [attr.aria-label]="'Delete Family Member ' + familyMembersTableData?.childName"
                                    (click)="addUpdateDeleteFamilyMember('delete', familyMembersTableData)"
                                    (keypress)="addUpdateDeleteFamilyMember('delete', familyMembersTableData)">
                                    <span style="font-size:14px"> Delete</span>
                                </span>
                            </ng-template>
                        </td>
                        <td class="notranslate"> {{familyMembersTableData.firstname}} </td>
                        <td class="notranslate">{{familyMembersTableData.lastname}} </td>
                        <td>{{familyMembersTableData.dT_BIRTH | date:'MM/dd/yyyy'}}</td>
                        <td> {{familyMembersTableData.age}} </td>
                        <td> {{familyMembersTableData.gender}} </td>
                        <td> {{familyMembersTableData.relation}} </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="7"> No records found </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="paginatorleft" let-state>
                    Showing {{state.totalRecords < 1 ? 0 : state.first + 1}} to {{state.totalRecords> 0 ?
                        ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
                            state.totalRecords) : 0}} of {{state.totalRecords ? state.totalRecords : 0}} entries
                            </ng-template>
                            <ng-template pTemplate="paginatorright" let-state> Entries Per Page </ng-template>
            </p-table>
        </div>
    </div>

    <div class="row pl-4">
        <div class="d-flex col-sm-6 pt-3"> </div>
        <div class="d-flex col-sm-6 pt-3 justify-content-end">
            <dfps-button class="ml-4" type="button" size="small" label="Add Family Member" _id="addFamilyMember"
                (click)="addUpdateDeleteFamilyMember('add')"></dfps-button>
        </div>
    </div>
</ng-template>