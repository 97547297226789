import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthTareService } from '@shared/services/auth-tare.service';
import { DataShareService } from '@shared/services/data-share.service';

@Component({
  selector: 'app-secondary-side-nav',
  templateUrl: './secondary-side-nav.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './secondary-side-nav.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SecondarySideNavComponent implements OnInit {

  @Input() showSideNavFor: string;
  familyId: number;
  profileId: number;
  isRoleCPA = this.authTareService.hasValidRole(this.authTareService.RoleCPA);

  constructor(private shareService: DataShareService,
    private authTareService: AuthTareService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.familyId = this.shareService.getDataFromMap('familyProfileInfo') ? this.shareService.getDataFromMap('familyProfileInfo').familyId : null;
    this.route.queryParamMap.subscribe(queryParams => {
      this.profileId = +queryParams.get('id');
    });
  }

}
