import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DfpsFormValidationDirective, DirtyCheck, FormValidationError, SET } from 'dfps-web-lib';
import { FamilyProfileService } from '../../services/family.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { FamilyProfile } from '../../model/family';
import { DataShareService } from '@shared/services/data-share.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-parental-expectations',
  templateUrl: './parental-expectations.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './parental-expectations.component.css'
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class ParentalExpectationsComponent extends DfpsFormValidationDirective implements OnInit {

  @ViewChild('errors', { static: false }) errorElement: ElementRef;

  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  parentalExpectationsForm: FormGroup;
  requestProcessing: Boolean;
  displayParentalExpectationsDetails: FamilyProfile;
  parentalExpectationsLookup$: Observable<any>;
  familyProfileSuccessMsg: string;
  renderFamilyProfile: boolean;

  constructor(
    private familyProfileService: FamilyProfileService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private appUtilService: AppUtilService,
    private shareService: DataShareService,
    private datePipe: DatePipe,
    public store: Store<{ dirtyCheck: DirtyCheck }>) {
    super(store);
  }

  ngOnInit(): void {
    this.renderFamilyProfile = false;
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.familyProfileService.getParentalExpectationsLookupsES().then(data => {
          this.parentalExpectationsLookup$ = data;
        });
      } else {
        this.familyProfileService.getParentalExpectationsLookups().then(data => {
          this.familyProfileService.parentalExpectationsLookup = data;
          this.parentalExpectationsLookup$ = data;
        });
      }
    });

    this.createForm();
    this.patchParentalExpectationsData();
    this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
  }

  createForm() {
    this.parentalExpectationsForm = this.formBuilder.group({
      familyId: [],
      employerFlexibility: ['', [Validators.required]],
      togethernessExpectations: ['', [Validators.required]],
      childAcademicPerformance: ['', [Validators.required]],
      schoolBehavior: ['', [Validators.required]],
      expressionEmotion: ['', [Validators.required]],
      physicalActivity: ['', [Validators.required]],
      ownPets: ['', [Validators.required]],
      familyDescription: ['', [Validators.required]]
    });
  }

  patchParentalExpectationsData() {
    this.familyProfileService.getFamilyProfileByEmail().subscribe((res) => {
      if (res && (res != null)) {
        this.displayParentalExpectationsDetails = res;
        const detailsResponse: any = this.displayParentalExpectationsDetails;
        this.parentalExpectationsForm.patchValue({
          familyId: detailsResponse.familyId,
          employerFlexibility: detailsResponse.employerFlexibility,
          togethernessExpectations: detailsResponse.togethernessExpectations,
          childAcademicPerformance: detailsResponse.childAcademicPerformance,
          schoolBehavior: detailsResponse.schoolBehavior,
          expressionEmotion: detailsResponse.expressionEmotion,
          physicalActivity: detailsResponse.physicalActivity,
          ownPets: detailsResponse.ownPets,
          familyDescription: detailsResponse.familyDescription
        });
        this.renderFamilyProfile = true;
      }
    },
      error => {
        this.toasterService.error(error, 'getFamilyProfileByEmail Error');
        this.renderFamilyProfile = true;
      });
  }

  onTrimSpace(formControl) {
    this.appUtilService.onTrimSpace(this.parentalExpectationsForm, formControl);
  }

  onSaveParentalExpectations() {
    if (this.validateFormGroup(this.parentalExpectationsForm)) {
      this.requestProcessing = true;
      this.familyProfileService.saveParentalExpectations(this.parentalExpectationsForm.value).subscribe((res) => {
        if (res) {
          this.shareService.addDataToMap('familyProfileSuccessMsg', this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a'));
          this.familyProfileSuccessMsg = this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a');
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          this.ngOnInit();
        }
        this.requestProcessing = false;
        window.scrollTo(0, 0);
      }, error => {
        this.requestProcessing = false;
        this.toasterService.error(error, 'parentalExpectations Error');
      });
    } else {
      if (this.errorElement) {
        // for focussing errors.
        setTimeout(() => {
          this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus();
        }, 1000);
      }
    }
  }

}
