import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  getFiles(id, url) {
    return this.http.get(`${url}${id}`);
  }

  uploadFiles(files, url) {
    return this.http.post(`${url}`, files);
  }

  deleteFile(id, idFile, url) {
    return this.http.post(`${url}${idFile}`, null);
  }

  downloadFile(id, idFile, url) {
    return this.http.get(`${url}${idFile}`, idFile);
  }

  linkImageWithAzure(url, request) {
    return this.http.put(`${url}`, request);
  }

}