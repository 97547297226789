import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { Directive, ElementRef, Renderer2, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appAccessibilityPDataViewTare]'
})

export class AccessibilityPDataViewTareDirective implements AfterContentInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  ngAfterContentInit() {
    setTimeout(() => {
      // Apparently, IE9 and above supports Array.forEach but not for NodeList, The DOM method document.querySelectorAll()(and a few others) return a NodeList.
      // All I had to do was to convert from nodeList to Array, and I did by this.
      // To operate on the list, e.g.using forEach(), the NodeList must first be converted to an Array.
      // I tried Array.from() to no avail as it requires ES6 or use ES6 - shim.

      this.ariaCurrentPage();

      const sortDropDownInput: any = this.el.nativeElement.querySelector('.p-dataview-header p-dropdown .p-dropdown .p-hidden-accessible input[type="text"]');
      if (sortDropDownInput) {
        this.renderer.setAttribute(sortDropDownInput, 'aria-label',
          this.el.nativeElement.querySelector('.p-dataview-header p-dropdown .p-dropdown span.p-dropdown-label.p-inputtext.p-placeholder').textContent)
      }

      const paginateNumbersArray: any[] = this.el.nativeElement.querySelectorAll('button.p-paginator-element');
      const entriesDropDownInput: any = this.el.nativeElement.querySelector('p-paginator p-dropdown .p-dropdown .p-hidden-accessible input[type="text"]');
      if (entriesDropDownInput) {
        this.renderer.removeAttribute(entriesDropDownInput, 'readonly');
        this.renderer.setAttribute(entriesDropDownInput, 'role', 'listbox');
        this.renderer.setAttribute(entriesDropDownInput, 'aria-label', this.el.nativeElement.querySelector('p-paginator p-dropdown .p-dropdown span.p-dropdown-label.p-inputtext').textContent
          + this.el.nativeElement.querySelector('p-paginator .p-paginator-rpp-options').textContent);


        this.paginateListBoxHasPop(entriesDropDownInput);
      }
      Array.from(paginateNumbersArray).forEach((x) => {
        this.renderer.setAttribute(x, 'role', 'button');
        const span = this.renderer.createElement('span');
        this.renderer.addClass(span, 'sr-only');
        let paginatorAriaLabel = '';
        let pageName = '';
        if (x.textContent !== '') {
          paginatorAriaLabel = 'Navigate To Page ' + x.textContent + ' in Data View';
        } else if (x.textContent === '') {
          if (x.className.includes('p-paginator-first')) {
            pageName = 'First Page';
          } else if (x.className.includes('p-paginator-prev')) {
            pageName = 'Previous Page';
          } else if (x.className.includes('p-paginator-next')) {
            pageName = 'Next Page';
          } else if (x.className.includes('p-paginator-last')) {
            pageName = 'Last Page';
          }
          paginatorAriaLabel = 'Navigate To ' + pageName + ' in Data View';
        }

        this.renderer.setAttribute(x, 'aria-label', paginatorAriaLabel);

        this.renderer.listen(this.el.nativeElement.querySelector('p-paginator'), 'click', () => {
          Array.from(paginateNumbersArray).forEach((z) => {
            this.renderer.removeAttribute(z, 'aria-current');
          });
          this.ariaCurrentPage();
        });
      });
    }, (1000));
  }

  ariaCurrentPage() {
    const activePage: any = this.el.nativeElement.querySelector('p-paginator button.p-highlight');
    if (activePage) {
      this.renderer.setAttribute(activePage, 'aria-current', 'page');
    }
  }

  paginateListBoxHasPop(entriesDropDownInput) {
    // artf147734, Defect ID 14391 A11Y JAWS entries per page announcement incorrect.
    // Please see Defect attachment where it explains about JAWS issues. This is working in chrome not ie IE, IE is not able to pick dynamic update of text content.
    const showingNoofItems = this.el.nativeElement.querySelector('p-paginator .p-paginator-left-content');
    this.renderer.setAttribute(showingNoofItems, 'aria-atomic', 'true');
    this.renderer.setAttribute(showingNoofItems, 'aria-live', 'assertive');
    this.renderer.setAttribute(entriesDropDownInput, 'aria-controls', 'paginateULPopupListId');
    this.renderer.setAttribute(entriesDropDownInput, 'aria-activedescendant', 'paginateULPopupListId');

    this.renderer.listen(this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-hidden-accessible input'), 'keyup', (e) => {
      if (this.el.nativeElement.querySelector(
        'p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list')) {
        const eventCode = (e.keyCode ? e.keyCode : e.which);
        if (eventCode === 32) {
          this.renderer.setAttribute(entriesDropDownInput, 'aria-expanded', 'true');
          const noOfEntriesPopupListPdropdown: any[] =
            this.el.nativeElement.querySelectorAll(
              'p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem');
          Array.from(noOfEntriesPopupListPdropdown).forEach((x) => {
            this.renderer.setAttribute(x, 'aria-label', x.querySelector('li.p-dropdown-item').getAttribute('aria-label'));
          });
          this.renderer.setAttribute(this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
            + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item.p-state-highlight'), 'aria-selected', 'true');
          const noOfEntriesPopupListLiSelected = this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
            + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item.p-state-highlight');
          if (noOfEntriesPopupListLiSelected.className.includes('p-state-highlight')) {
            this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'aria-selected', 'true');
            this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'id', 'paginateULPopupListId');
            this.renderer.setAttribute(entriesDropDownInput, 'aria-label',
              noOfEntriesPopupListLiSelected.getAttribute('aria-label') + this.el.nativeElement.querySelector('p-paginator .p-paginator-rpp-options').textContent);
          }

        } else {
          const noOfEntriesPopupListLi: any[] =
            this.el.nativeElement.querySelectorAll('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
              + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item');
          this.renderer.listen(entriesDropDownInput, 'keyup', (event) => {
            if (this.el.nativeElement.querySelector(
              'p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list')) {
              const eventCodeType = (event.keyCode ? event.keyCode : event.which);
              if (eventCodeType === 35 || eventCodeType === 36 || eventCodeType === 38 || eventCodeType === 40) {
                Array.from(noOfEntriesPopupListLi).forEach((x) => {
                  const noOfEntriesPopupListLiSelected = this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
                    + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item.p-state-highlight');
                  this.renderer.removeAttribute(x, 'aria-selected');
                  this.renderer.removeAttribute(x, 'id');
                  if (noOfEntriesPopupListLiSelected.className.includes('p-state-highlight')) {
                    this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'aria-selected', 'true');
                    this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'id', 'paginateULPopupListId');
                    this.renderer.setAttribute(entriesDropDownInput, 'aria-label',
                      noOfEntriesPopupListLiSelected.getAttribute('aria-label') + this.el.nativeElement.querySelector('p-paginator .p-paginator-rpp-options').textContent);
                  } else {
                    this.renderer.removeAttribute(entriesDropDownInput, 'aria-expanded');
                  }
                });
              }
            }
          });
        }
      }
    });
  }

}
