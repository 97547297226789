import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DfpsCommonValidators, DfpsFormValidationDirective, DirtyCheck, FormUtils, FormValidationError, SET } from 'dfps-web-lib';
import { FamilyProfileService } from '../../services/family.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { DataShareService } from '@shared/services/data-share.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { DatePipe } from '@angular/common';
import { SharedValidator } from '@shared/validators/shared.validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agency-history',
  templateUrl: './agency-history.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/ngx-bootstrap/datepicker/bs-datepicker.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './agency-history.component.css'
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class AgencyHistoryComponent extends DfpsFormValidationDirective implements OnInit {

  @ViewChild('errors', { static: false }) errorElement: ElementRef;

  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  agencyHistoryForm: FormGroup;
  requestProcessing: Boolean;
  agencyHistoryDetails: any;
  familyProfilePageLookups$: Observable<any>;
  familyProfileSuccessMsg: string;
  familyProfileInfo: any;
  dspAgencyWorkerInfo: boolean;
  tempAgencyHistoryFormValue: any;
  renderFamilyProfile: boolean;

  maxDate = { year: new Date().getUTCFullYear() + 120, month: 12, day: 31 }
  minDate = { year: new Date().getUTCFullYear() - 120, month: 12, day: 31 }

  constructor(
    private familyProfileService: FamilyProfileService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private appUtilService: AppUtilService,
    private shareService: DataShareService,
    private datePipe: DatePipe,
    public store: Store<{ dirtyCheck: DirtyCheck }>) {
    super(store);
  }

  ngOnInit(): void {
    this.renderFamilyProfile = false;
    this.familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.familyProfileService.getFamilyProfilePageLookupsES().then(data => {
          this.familyProfilePageLookups$ = data;
          this.familyProfilePageLookups$['indActiveAgency'] = AppUtilService.CYESNO_SPANISH;

        });
      } else {
        this.familyProfileService.getFamilyProfilePageLookups().then(data => {
          this.familyProfileService.familyProfilePageLookups = data;
          this.familyProfilePageLookups$ = data;
          this.familyProfilePageLookups$['indActiveAgency'] = AppUtilService.CYESNO;
        });
      }
    });


    this.createForm();
    this.patchAgencyHistoryData();
    this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
  }

  createForm() {
    this.agencyHistoryForm = this.formBuilder.group({
      familyId: [this.familyProfileInfo ? this.familyProfileInfo.familyId : null],
      indActiveAgency: ['N', [Validators.required]],
      approvedStartDate: [''],
      approvedEndDate: [''],
      agencyName: [''],
      agencyCity: [''],
      agencyState: ['TX'],
      caseManagerFirstName: [''],
      caseManagerLastName: [''],
      caseManagerEmail: [''],
      caseManagerPhone: [''],
      txtComments: ['']
    }, {
      validators: [SharedValidator.compareDates('approvedStartDate', 'approvedEndDate')]
    });
  }

  onWorkingWithAgencyChange() {
    if (this.agencyHistoryForm.value.indActiveAgency === 'Y') {
      this.agencyHistoryForm.get('approvedStartDate').setValidators([Validators.required, DfpsCommonValidators.validateDate]);
      this.agencyHistoryForm.get('approvedEndDate').setValidators([DfpsCommonValidators.validateDate]);
      this.agencyHistoryForm.get('agencyName').setValidators([Validators.required]);
      this.agencyHistoryForm.get('agencyCity').setValidators([Validators.required]);
      this.agencyHistoryForm.get('agencyState').setValue('TX');
      this.agencyHistoryForm.get('agencyState').setValidators([Validators.required]);
      this.agencyHistoryForm.get('caseManagerFirstName').setValidators([Validators.required]);
      this.agencyHistoryForm.get('caseManagerLastName').setValidators([Validators.required]);
      this.agencyHistoryForm.get('caseManagerEmail').setValidators([Validators.required, SharedValidator.validateEmailAddress]);
      this.agencyHistoryForm.get('caseManagerPhone').setValidators([Validators.required, SharedValidator.phoneNumberPattern]);
      // save previously entered form value to retrive back and show on form when indActiveAgency value is changed back.
      this.tempAgencyHistoryFormValue ? this.agencyHistoryForm.setValue(this.tempAgencyHistoryFormValue) : null;
      this.agencyHistoryForm.get('indActiveAgency').setValue('Y');
      this.tempAgencyHistoryFormValue = null;
      this.dspAgencyWorkerInfo = true;
    } else {
      this.validationErrors = [];
      this.validationErrorsLength = 0;
      this.tempAgencyHistoryFormValue = this.agencyHistoryForm.value;
      this.createForm();
      this.dspAgencyWorkerInfo = false;
    }
    this.agencyHistoryForm.updateValueAndValidity();
  }

  patchAgencyHistoryData() {
    this.familyProfileService.getFamilyProfileByEmail().subscribe((res) => {
      if (res && (res != null)) {
        this.agencyHistoryDetails = res;
        const detailsResponse: any = this.agencyHistoryDetails;
        const agencyHistoryResponse: any = detailsResponse.agencyHomeDTO;
        this.agencyHistoryForm.patchValue({
          familyId: detailsResponse.familyId,
          indActiveAgency: agencyHistoryResponse.indActiveAgency,
          approvedStartDate: agencyHistoryResponse.approvedStartDate ? this.datePipe.transform(agencyHistoryResponse.approvedStartDate, 'MM/dd/yyyy') : null,
          approvedEndDate: agencyHistoryResponse.approvedEndDate ? this.datePipe.transform(agencyHistoryResponse.approvedEndDate, 'MM/dd/yyyy') : null,
          agencyName: agencyHistoryResponse.agencyName ? agencyHistoryResponse.agencyName : null,
          agencyCity: agencyHistoryResponse.agencyCity ? agencyHistoryResponse.agencyCity : null,
          agencyState: ((agencyHistoryResponse.agencyState === '' && agencyHistoryResponse.agencyState == null) ? 'TX' : agencyHistoryResponse.agencyState),
          caseManagerFirstName: agencyHistoryResponse.caseManagerFirstName ? agencyHistoryResponse.caseManagerFirstName : null,
          caseManagerLastName: agencyHistoryResponse.caseManagerLastName ? agencyHistoryResponse.caseManagerLastName : null,
          caseManagerEmail: agencyHistoryResponse.caseManagerEmail ? agencyHistoryResponse.caseManagerEmail : null,
          caseManagerPhone: agencyHistoryResponse.caseManagerPhone ? FormUtils.formatPhoneNumber(agencyHistoryResponse?.caseManagerPhone?.toString()) : null,
          txtComments: agencyHistoryResponse.txtComments ? agencyHistoryResponse.txtComments : null
        });
      }
      this.onWorkingWithAgencyChange();
      this.renderFamilyProfile = true;
    },
      error => {
        this.toasterService.error(error.error.message, 'getAgencyHistory Error');
        this.renderFamilyProfile = true;
      });
  }

  onTrimSpace(formControl) {
    this.appUtilService.onTrimSpace(this.agencyHistoryForm, formControl);
  }

  onSaveAgencyHistory() {
    this.agencyHistoryForm.get('caseManagerPhone').setValue(this.agencyHistoryForm.value.caseManagerPhone.replace(/[^+\d]+/g, ''));
    if (this.agencyHistoryForm.value.indActiveAgency === 'Y' &&
      (this.agencyHistoryForm.controls.approvedStartDate.value == null || this.agencyHistoryForm.controls.approvedStartDate.value.toString().trim().length === 0)) {
      this.agencyHistoryForm.controls.approvedStartDate.setErrors({ required: true });
      // for focussing errors.
      setTimeout(() => { this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus(); }, 1000);
    }
    if (this.validateFormGroup(this.agencyHistoryForm)) {
      this.requestProcessing = true;
      this.agencyHistoryForm.value.approvedStartDate =
        (this.agencyHistoryForm.value.approvedStartDate && this.agencyHistoryForm.value.approvedStartDate != '' && this.agencyHistoryForm.value.approvedStartDate != null) ?
          this.datePipe.transform(this.agencyHistoryForm.value.approvedStartDate, 'YYYY-MM-dd') : null;
      this.familyProfileService.saveAgencyHistory(this.agencyHistoryForm.value).subscribe((res) => {
        if (res) {
          this.shareService.addDataToMap('familyProfileSuccessMsg', this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a'));
          this.familyProfileSuccessMsg = this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a');
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          this.ngOnInit();
        }
        this.requestProcessing = false;
        window.scrollTo(0, 0);
      }, error => {
        this.requestProcessing = false;
        this.toasterService.error(error, 'saveAgencyHistory Error');
      });
    } else {
      if (this.errorElement) {
        // for focussing errors.
        setTimeout(() => {
          this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus();
        }, 1000);
      }
    }
  }

}
