/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/

import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from "@azure/msal-angular";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig, protectedResources } from "./modules/shared/config/auth-configb2c";

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();


  protectedResources.forEach(protectedResource =>
    protectedResourceMap.set(protectedResource.endpoint, [
      { httpMethod: 'GET', scopes: [...protectedResource.scopes.read] },
      { httpMethod: 'POST', scopes: [...protectedResource.scopes.write] },
      { httpMethod: 'PUT', scopes: [...protectedResource.scopes.write] },
      { httpMethod: 'DELETE', scopes: [...protectedResource.scopes.write] }
    ]));

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}
