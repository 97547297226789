import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { FileUpload } from 'primeng/fileupload';
import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit {

  @Input() fileUploadinfo: any;
  @Output() emitFileUploadActions = new EventEmitter();

  uploadedFilesList: any[] = [];
  currentDate = new Date();
  filesChanged: boolean;

  @ViewChild('createUploader', { static: true }) createUploader;

  constructor(
    private activeModal: NgbActiveModal,
    private renderer: Renderer2,
    private fileUploadService: FileUploadService) { }

  ngOnInit() {
    this.onPageLoad();
  }

  onPageLoad() {
    this.uploadedFilesList.length = 0;
    /* this.fileUploadService.getFiles(this.fileUploadinfo.id, this.fileUploadinfo.urls.getUrl).subscribe((res) => {

      // this.uploadedFilesList.length = 0;
      if (res) {
        // this.uploadedFilesList = res;
      }
    }); */
  }

  onSelect(event, uploader: FileUpload): void {
    this.checkDuplicateFiles(event, uploader);
    this.checkNoOfFilesLimitFileNameLength(event, uploader, this.fileUploadinfo.fileLimit, 100);
    this.checkForCommaInFileName(event, uploader);
    // this.readyToUploadFiles = uploader.files;
    this.focusUploderPMsgs(uploader); // Accessibility Defect Fix
  }

  checkForCommaInFileName(event, uploader: FileUpload): void {
    const clonedUploaderFiles = Object.assign([], uploader.files);
    for (const file of clonedUploaderFiles) {
      const fileIndex = uploader.files.indexOf(file);
      if (file.name.includes(',') && fileIndex !== -1) {
        uploader.msgs.push({ severity: 'error', summary: file.name, detail: 'The file name contains special characters like comma. Please retry the file by removing the comma from the file name.' });
        uploader.files.splice(fileIndex, 1);
      }
    }
  }

  checkNoOfFilesLimitFileNameLength(event, uploader: FileUpload, filesLimit: number, fileNameLength: number): void {
    // If Any Issue Occurs Future, then breakown below for loop code into two functions.

    if (this.uploadedFilesList.length > filesLimit || uploader.files.length > filesLimit || (this.uploadedFilesList.length + uploader.files.length) > filesLimit) {
      uploader.msgs.push({ severity: 'error', detail: 'Can Not Upload More Than ' + filesLimit + ' Files.' });
    }

    const clonedUploaderFiles = Object.assign([], uploader.files);
    for (const file of clonedUploaderFiles) {
      const fileIndex = uploader.files.indexOf(file);
      if (file.name.length > fileNameLength && fileIndex !== -1) {
        uploader.msgs.push({ severity: 'error', summary: file.name, detail: 'File Name is too long.maximum length of file name is ' + fileNameLength + ' characters.' });
        uploader.files.splice(fileIndex, 1);
      }
      if (this.uploadedFilesList.length > filesLimit || uploader.files.length > filesLimit || (this.uploadedFilesList.length + uploader.files.length) > filesLimit) {
        uploader.files.pop();
      }
    }
  }

  checkDuplicateFiles(event, uploader: FileUpload): void {

    // let uniqueFiles = uploader.files.filter(item => !this.uploadedFilesList.some(other => item.name === other.fileName));

    const duplicateFiles = uploader.files.filter(item => this.uploadedFilesList.some(other => item.name === other.fileName));

    for (const file of duplicateFiles) {
      const fileIndex = uploader.files.indexOf(file);
      uploader.msgs.push({ severity: 'error', summary: 'Duplicate File Upload', detail: file.name + ' file already present in Database.' });
      uploader.files.splice(fileIndex, 1);
    }
  }

  onRemove(event, file: File, uploader: FileUpload) {
    const fileindex = uploader.files.indexOf(file);
    uploader.remove(event, fileindex);
  }

  uploadHandler(event, uploader: FileUpload): void {
    if (uploader.files.length > 0) {
      const files = uploader.files;
      uploader.clear();

      const formData: FormData = new FormData();
      for (const key in files) {
        if (key) {
          formData.append('file', files[key]);
        }
      }

      this.fileUploadService.uploadFiles(formData, this.fileUploadinfo.urls.uploadUrl).subscribe((resp) => {
        /* this.onPageLoad();
        uploader.msgs.length = 0;
        uploader.msgs.push({ sticky: false, severity: 'success', summary: 'Success Message', detail: 'Files Uploaded Successfully' });
        this.focusUploderPMsgs(uploader); */

        if (this.fileUploadinfo.urls.linkImageWithAzureUrl) {
          resp['familyId'] = this.fileUploadinfo.id.toString()
          this.fileUploadService.linkImageWithAzure(this.fileUploadinfo.urls.linkImageWithAzureUrl, resp).subscribe((res) => {
            this.filesChanged = true;
            this.closeModal();
          }, error => { uploader.files = files; });
        } else if (!this.fileUploadinfo.urls.linkImageWithAzureUrl) {
          this.filesChanged = true;
          this.closeModal();
        }
      },
        error => {
          uploader.files = files;
        }
      );
    }

  }

  deleteFile(idfile, uploader?: FileUpload) {
    if (uploader && uploader.msgs) {
      uploader.msgs.length = 0;
    }
    this.fileUploadService.deleteFile(this.fileUploadinfo.id, idfile, this.fileUploadinfo.urls.deleteUrl).subscribe((res) => {
      this.filesChanged = true;
      this.onPageLoad();
    });
  }

  focusUploderPMsgs(uploader: FileUpload) {
    if (uploader.msgs.length > 0) {
      setTimeout(() => {
        this.renderer.setAttribute(this.createUploader.content.nativeElement.querySelector('.p-messages'), 'tabindex', '0');
        this.renderer.listen(this.createUploader.content.nativeElement.querySelector('.p-messages .p-messages-close'), 'click', () => {
          this.renderer.selectRootElement(this.createUploader.el.nativeElement.querySelector('.p-fileupload-choose input[type="file"]'), true).focus();
        });
        this.renderer.selectRootElement(this.createUploader.content.nativeElement.querySelector('.p-messages'), true).focus();
        return this.renderer.setAttribute(this.createUploader.content.nativeElement.querySelector('.p-messages .p-messages-close'), 'aria-label', 'Close Messages Button');

      });
    }
  }

  downloadFile(idfile) {
    this.fileUploadService.downloadFile(this.fileUploadinfo.id, idfile, this.fileUploadinfo.urls.downloadUrl).subscribe(res => {
      /* const contentDispositionHeader: string = res.headers.get('content-disposition');
      const parts: string[] = contentDispositionHeader.split(';');
      const filename = parts[1].split('=')[1];
      const blob = new Blob([res.body], { type: 'pdf/image/csv/xls/xlsx' });
      saveAs(blob, filename); */

      // let url = window.URL.createObjectURL(blob);
      // window.open(url);

      /*  let blob;
       blob = new Blob([res], { type: 'application/pdf' });
       importedSaveAs(blob, ''); */

      /* var a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([res], { type: 'application/pdf/image/csv' }));
      a.download = 'Interview_Summary';
      // start download
      a.click(); */
    });
  }

  closeModal() {
    this.activeModal.dismiss();
    if (this.filesChanged)
      this.emitFileUploadActions.emit(true);
  }

  ngAfterViewInit() {
    // Accessibility Defect Fix
    this.renderer.setAttribute(this.createUploader.el.nativeElement.querySelector('.p-fileupload-choose input[type="file"]'), 'aria-label', 'Choose Button');
  }

}
