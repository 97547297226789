export const dfpsValidatorErrors = {
    cannotContainSpace: 'Value cannot contain space.',
    validateDate: '$actualValue is not valid. Please verify date is correct and in the format MM/DD/YYYY.',
    validateNumber: '$actualValue is not valid. Please enter a valid number.',
    dateFrom: 'The From date must be before or the same as the To date.',
    fromDate: 'The From date must be before or the same as the To date.',
    approvedStartDate: 'The Approved From date must be before or the same as the Approved To date.',
    startDate: 'The Start date must be before the Closure or Term date.',
    formValidated: 'Please Validate before saving',
    required: 'Field is required. Please enter a value.',
    requiredForAddressValidation: 'Field is required for address validation. Please enter a value.',
    minlength: 'needs to be atleast $requiredLength characters but the current string length is $actualLength',
    maxlength: 'Please enter a value no more than $requiredLength characters, the current length is $actualLength',
    cityPattern: '"$actualValue" is not valid. Please enter a city with no more than 20 characters.',
    zipPattern: '"$actualValue" is not valid.Please enter a number with exactly 5 digits.',
    validateEmailAddress: '"$actualValue" is not valid.Please enter a valid email address.',
    zipExtensionPattern: '"$actualValue" is not valid. Please enter a number with exactly 4 digits.',
    // tslint:disable-next-line: max-line-length
    ssnPattern: '"$actualValue" is not valid. Please enter the Social Security Number for this individual, if known. The number you entered is not a valid SSN.',
    contractMgr: 'Navigate to the Staff dialog to populate the Contract Mgr field.',
    organizationEINAndLegalName: 'Organization EIN and Legal Name are required.',
    sponsorName: 'DFPS Sponsor is required.',
    procrementNumberPattern: 'Procurement number must be between 1-16 alphanumeric characters and may include dashes (-).',
    selectedContractPeriod: 'Please select at least one row to perform this action.',
    salaryAmount: 'The Amount field must be greater than or equal to the Used field.',
    contractVersionLocked: 'The Budget Transfer is valid for most recent Version only and Version must be locked.',
    paymentApprovals: 'Please select at least one row to perform this action.',
    from: 'Please select a row to transfer FROM.',
    to: 'Please select a row to transfer To.',
    resourceType: 'You must enter at least one required search parameter before beginning a search.',
    earlyTerm: `The 'Early Term' date must be at least one day after the 'Current' date.`,
    earlyAndTermDates: 'The Closure date must be before or the same as the Term date.',
    startDateComparison: 'The Start date must be before the Closure or Term date.',
    scorContractNumber: 'The Contract Number is not valid. Please enter a valid Contract Number',
    contractNumber: 'The Contract Number must be validated.',
    fromToEq: 'Transfer From row cannot be the same as Transfer To row.',
    invalidAmt: 'There is not enough budget in the first item to perform this transfer.',
    validUnits: 'Number of units is too large- reduce  budget total or increase unit rate.',
    unitRate: 'The computed Unit Rate cannot exceed 9999.99.',
    service: 'The service code is not payable within this date range.',
    unitRatePaymentType: ' Required when Payment Type is Unit Rate or Variable Unit Rate.',
    costReim: 'Cost Reim is not a valid option for contracts w/o budget limits.',
    federalMatch: '"$actualValue" is not valid. Please enter a value with 3 or less digits.',
    localMatch: 'Please enter a value with 3 or less digits.',
    invalidDollarAmount: '"$actualValue" is not valid. Please enter a valid dollar amount with a decimal point followed by two numbers.',
    budgetLimit: 'Cost Reim is not a valid option for contracts w/o budget limits.',
    percentRange: 'No Show Percent must be between 0 and 100 percent.',
    effectiveDate: 'The Effective date must be before or the same as the End date.',
    alphaNumeric: '"$actualValue" is not valid. $fieldName must include only alphanumeric characters.',
    alphaNumericHyphen: '"$actualValue" is not valid. $fieldName must include only alphanumeric or hyphen characters.',
    // tslint:disable-next-line: max-line-length
    resourceSearchMHMRCode:
        'When searching on a MHMR Code, you must enter between 2-5 characters before beginning a search.',
    // tslint:disable-next-line: max-line-length
    resourceSearchFacilityNumber:
        '"$actualValue" is not valid. Please enter a number that is between 2 - 8 digits.',
    // tslint:disable-next-line: max-line-length
    resourceSearchResourceNumber:
        '"$actualValue" is not valid. Please enter a number that is between 2 - 9 digits.',
    // tslint:disable-next-line: max-line-length
    resourceSearchAgencyAcctID:
        '"$actualValue" is not valid. Please enter a number that is between 2 - 9 digits.',
    // tslint:disable-next-line: max-line-length
    resourceSearchContractNumber:
        '"$actualValue" is not valid. Please enter a number that is between 2 - 9 digits.',
    resourceSearchPhoneNumber:
        'Phone number must contain 10 numbers only and no special characters.',
    // tslint:disable-next-line: max-line-length
    resourceSearchResourceName:
        '"$actualValue" is not valid. Resource Name must be a value with at least 2 characters in any combination of letters or digits.',
    resourceSearchAge: '"$actualValue" is not valid.',
    // tslint:disable-next-line: max-line-length
    resourceSearchNumberType: 'When searching on a Identification Number, you must select an Identification Type before beginning a search.',
    resourceSearchCityRequired: 'You must enter City to search by Street Address.',
    resourceSearchCityAlphaPattern:
        '"$actualValue" is not valid. Please enter a city with no more than 20 characters.',
    numericValues: 'Please enter numeric values',
    max: '"$actual" is more than max allowed value of $max',
    invoiceSearchRequiredFields: 'Please enter a Region, Invoice Month, and Invoice Year',
    monthPattern: '"$actualValue" is not valid. The valid month range is between 1 and 12',
    yearPattern: '"$actualValue" is not valid. Please enter a valid year with 4 digits (min 1850)',
    validateMaxId: '"$actualValue" is not valid. ID fields should be 10 digits or less',
    decimalNumber:
        '"$actualValue" is not valid. Please enter a number with at least one digit followed by a decimal point and two digits',
    validateYearMonth: 'Month and Year must be on or before the current month and year.',
    csliValidationMsg: '"$actualValue" is not valid. Please enter a value with 2 or less digits',
    negativeAmount: 'This is not an adjustment invoice; negative entries are not allowed.',
    dateRange: 'Date Range must be entered before launching report',
    validate: 'Please Validate before saving.',
    negativeNumber: 'Unit Rate cannot be negative.',
    SSM_FIN_CR_UNIT_RATE_MAX: 'The computed Unit Rate cannot exceed 9999.99.',
    SSM_FIN_NO_POS_NEG_AMTS: 'You cannot have both positive and negative amounts on the same line item.',
    SSM_FIN_NO_NEGTVE_ENTRY: 'This is not an adjustment invoice; negative entries are not allowed.',
    cpsProgramConstraint: `$actualValue must be entered before validating.`,
    cpsProgramConstraintAcctNumber: `"$actualValue" is not valid. 
      Person ID, Program, Acct. Type, Status and Account Number must be entered before validating.`,
    finanAcctSaveConstraintAcctNumber: `"$actualValue" is not valid. 
      Please enter a value with 15 or less digits.`,
    validateOffsetSum: 'The Offsetting Items cannot be greater than the sum of the categories.',
    finanAcctValidateConstraintPersonId: `"$actualValue" is not valid. 
      Please enter a value with 16 or less digits.`,
    feePaid: 'The Fee Paid is greater than the calculated Item Total -- $actualValue. Please verify and re-enter.',
    deliveredMonth: 'Month must be on or before the current month.',
    deliveredYear: 'Year must be on or before the current year.',
    deliveredServiceUnitRate: 'This Delivered Service is not a Cost Reimbursement, please enter a Rate.',
    dayPattern: ' The valid day range is between 1 and 31.',
    dayRange: 'From Day must be less than or equal to the to Day',
    invalidMonthDay: 'You have entered an invalid day for this month',
    SSM_FIN_SVC_YR_GRTR_MAX: 'The Service Year cannot exceed 2 years prior to the current fiscal year.',
    feePaidNegative: 'This is not an adjustment invoice; negative entries are not allowed.',
    MSG_MO_YR_GRTR_REC: 'Invoice Received Date must be greater than or equal to Foster Month, Year and To Day',
    SSM_NO_NEG_UNIT_RATE: 'The unit rate can not be negative.',
    MSG_VAL_SAVE: 'Please Validate before saving',
    SSM_INC_POS_REVERSAL: 'The Income cannot be positive for a Reversal line item.',
    SSM_FIN_INC_NEG: 'The Income cannot be negative for an Original/Adjustment line item.',
    SSM_FIN_INC_GRTR_THN_ITM: 'The Income is greater than the calculated Item Total -- $actualValue.  Please verify and re-enter.',
    InvoiceReceivedDate: 'Date must be before or the same as the current date.',
    InvoiceYearMonth: 'Month and Year must be on or before the current month and year.',
    INVOICE_FIN_YR_GR: 'Received Month and Year must be equal to or greater than Invoice Month and Year.',
    CLAIMED_AMOUNT_NEGATIVE: 'This is not an adjustment invoice; negative entries are not allowed.',
    accountNumber: 'Field is required. Please enter a value.',
    phoneNumberPattern: `"$actualValue" is not valid. Please enter a 10 digit phone number. Parentheses and dashes are optional.`,
    validateOneTimePayment: `Validation not required for Payment Group with a Category as One Time Payment`,
    MSG_DEBIT_DESC: 'Description is required for all transactions that are Debits.',
    achDate: 'Date must be before or the same as the current date.',
    MSG_FIN_TRAN_AMOUNT: 'Entered amount cannot be more than the Current Available Balance.',
    MSG_FIN_TRAN_AMOUNT_REQ: 'You must enter transaction amount greater than zero.',
    minSelections: 'Please select at least one row to perform this action.',
    maxSelections: `You have selected "$actualValue" Payment Groups. Please unselect Payment Groups to be 50 or less.`,
    achNo: 'You must specify ACH number for the payment(s)',
    achDateRequired: 'You must specify ACH date for the payment(s)',
    checkNumber: 'You must specify Check number to void',
    voidDateRequired: 'You must specify Check void date.',
    MSG_FIN_PAYEE_NM_REQD: 'Payee Name is required for Save.',
    MSG_FIN_VALD_PYMT_GRP: 'You must first Validate a Payment Group with a Category = Contract or Person prior to saving. Please validate.',
    MSG_DEBIT_CHECKNBR: 'Check Number is required for all transactions that are Debits.',
    MSG_FIN_SUBCATEGORY_REQ: 'Sub-Category is required for the selected Category.',
    MSG_FIN_ACCTBAL_NEG_ON_ADD: `Transaction cannot be added/saved, as it would make the financial account balance as Negative.
    Selected category does not allow Negative balance.`,
    paymentGroupSearchIdPattern: '"$actualValue" is not valid. ID fields should be 10 digits or less.',
    MSG_SELECT_ROW_ACTION: 'Please select at least one row to perform this action.',
    validateFutureDate: 'Date cannot be a future date.',
    einPattern: 'This is not valid EIN. Please enter a valid 9-digit EIN.',
    SSM_CON_NOT_VALIDATED: 'The Resource ID must be validated.',
    validateCurrency: 'Please enter a valid dollar amount with a decimal point followed by two numbers.',
    MSG_DEBIT_CKNBR: 'A check # or ACH is required for all transactions that are Debits.',
    futureMonthYear: 'Month and Year must be on or before the current month and year.',
    feePaidLessThanZero: 'You cannot have both positive and negative amounts on the same line item.',
    MSG_VALIDATE_SAVE: 'Please Validate - Person Id, Program, Account Type & Status before saving it.',
    atleastOneFieldRequired: 'Minimum one field required for search.',
    CRMValidation: 'Cost Reimbursement Detail must be completed before Invoice can be marked Ready for Validation.'
};
