import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChildSearchService } from '../../services/child.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { DataShareService } from '@shared/services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import { AppUtilService } from '@shared/services/app-util.service';
import { AuthTareService } from '@shared/services/auth-tare.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './child-profile.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    "../../../../../.././node_modules/primeicons/primeicons.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/css/bootstrap-glyphicons.css',
    './child-profile.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChildProfileComponent implements OnInit {

  idChild: number;
  idSibling: any;
  childProfileResponse: any;
  renderPage: boolean;
  isUserLoggedIn: boolean;
  familyProfileInfo: any;
  groupedMedia: any;
  activeLangSpanish$: Observable<boolean>;
  childSearchLookup$: Observable<any>;
  isRoleFamily = this.authTareService.hasValidRole(this.authTareService.RoleFamily);

  constructor(
    private toasterService: ToastrService,
    private childService: ChildSearchService,
    private route: ActivatedRoute,
    private authService: MsalService,
    private authTareService: AuthTareService,
    private shareService: DataShareService,
    private appUtilService: AppUtilService,
    private router: Router,) { }

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    this.route.queryParamMap.subscribe(queryParam => {
      this.idChild = +queryParam.get('id');
      this.idSibling = queryParam.get('idSibling') ? +queryParam.get('idSibling') : queryParam.get('idSibling');
    });
    this.familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');
    this.childService.getTAREProfile(this.isUserLoggedIn, this.idChild, this.isUserLoggedIn ? this.familyProfileInfo.familyId : null, false).subscribe((res) => {
      this.childProfileResponse = { ...res };
      if (this.childProfileResponse?.allMedia?.length > 0) {
        this.childProfileResponse?.allMedia.sort((a, b) => (a.galleryName < b.galleryName ? -1 : 1));
        this.groupedMedia = this.appUtilService.groupBy(this.childProfileResponse?.allMedia, "galleryName");
      }

      this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
        this.activeLangSpanish$ = of(value);
        if (value && this.childProfileResponse) {
          this.childService.getChildSearchPageLookupsES().then(data => {
            this.childSearchLookup$ = data;
            this.childProfileResponse.gender = this.appUtilService.convertAPIRespTxtToESFromLookups(this.childSearchLookup$['gender2'], this.childProfileResponse?.gender, 'gender');
            this.childProfileResponse.race = this.appUtilService.convertAPIRespTxtToESFromLookups(this.childSearchLookup$['race'], this.childProfileResponse?.race, 'race');
            this.childProfileResponse.ethnicity = this.appUtilService.convertAPIRespTxtToESFromLookups(this.childSearchLookup$['ethnicity'], this.childProfileResponse?.ethnicity, 'ethnicity');
            this.childProfileResponse.region = this.appUtilService.convertAPIRespTxtToESFromLookups(this.childSearchLookup$['regions'], this.childProfileResponse?.region, 'regions');
            this.childProfileResponse.primaryLanguage = this.appUtilService.convertAPIRespTxtToESFromLookups(this.childSearchLookup$['languages'], this.childProfileResponse?.primaryLanguage, 'languages');
          });
        } else {
          this.childService.getChildSearchPageLookups().then(data => {
            this.childService.searchPageLookups = data;
            this.childSearchLookup$ = data;
            this.childProfileResponse = { ...res };
          });
        }
      });
    },
      error => {
        this.toasterService.error(error, 'getChildProfile Error');
      });
  }

  inquiryChild() {
    this.router.navigate(['/child/childSearch/inquiry'], { queryParams: { idChild: this.idChild } });
  }

  viewGroupProfile() {
    this.router.navigate(['/child/childSearch/siblingProfile'], { queryParams: { id: this.idSibling } });
  }

  toggleBookmarkChild() {
    this.childProfileResponse.isUserBookmark = !this.childProfileResponse.isUserBookmark;
    this.childService.addRemoveBookmark(this.idChild, this.familyProfileInfo.familyId, this.childProfileResponse.isUserBookmark, false).subscribe((res) => {
    },
      error => {
        this.childProfileResponse.isUserBookmark = !this.childProfileResponse.isUserBookmark;
        this.toasterService.error(error, 'addRemoveBookmark Error');
      });
  }

}
