import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { FamilyMember } from '../model/family';

@Injectable({
  providedIn: 'root'
})
export class FamilyProfileService {
  searchPageApi = environment.searchPageApi;
  profilePageApi = environment.profilePageApi;

  readonly FAMILY_PROFILE_LOOKUPS_URL: string = '/PublicLookup/GetFamilyDetailPageValues';
  readonly PARENTAL_EXPECTATIONS_LOOKUPS_URL: string = '/PublicLookup/GetFamilyParentalExpectationsPageValues';
  readonly CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL: string = '/PublicLookup/GetChildCharacteristicPreferencePageValues';
  readonly ADOPTION_INTERESTS_LOOKUPS_URL: string = '/PublicLookup/GetFamilyAdoptionInterestPageValues';
  readonly FAMILY_PROFILE_LOOKUPS_URL_SPANISH: string = '../../../../assets/locales/lookups/es-getFamilyProfilePageLookups.json';
  readonly PARENTAL_EXPECTATIONS_LOOKUPS_URL_SPANISH: string = '../../../../assets/locales/lookups/es-getParentalExpectationsLookups.json';
  readonly CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL_SPANISH: string = '../../../../assets/locales/lookups/es-getChildCharacteristicPreferencesLookups.json';
  readonly ADOPTION_INTERESTS_LOOKUPS_URL_SPANISH: string = '../../../../assets/locales/lookups/es-getAdoptionInterestsLookups.json';

  readonly GET_FAMILY_PROFILE_URL: string = '/GetFamilyById?id=';

  readonly GET_FAMILY_PROFILE_BY_EMAIL_TOKEN_URL: string = '/Profile/GetFamilyProfileForUpdate';
  readonly CREATE_FAMILY_PROFILE_URL: string = '/Profile/NewFamilyProfile';
  readonly UPDATE_FAMILY_PROFILE_URL: string = '/Profile/UpdateFamilyProfile';

  readonly UPLOAD_FAMILY_PROFILE_IMAGE_URL: string = '/Search/Upload';
  readonly LINK_FAMILY_WITH_AZURE_IMAGE: string = '/Profile/LinkFamilyWithAzureImage';

  readonly ADD_FAMILY_MEMBER_URL: string = '/Profile/NewFamilyMember';
  readonly UPDATE_FAMILY_MEMBER_URL: string = '/Profile/UpdateFamilyMember';
  readonly DELETE_FAMILY_MEMBER_URL: string = '/Profile/DeleteFamilyMember?familyMemberId=';

  readonly UPDATE_PARENTAL_EXPECTATIONS_URL: string = '/Profile/UpdateParentalExpectation';

  readonly GET_ADOPTION_INTERESTS_URL: string = '/Profile/GetAdoptionInterestByFamilyID?familyId=';
  readonly UPDATE_ADOPTION_INTERESTS_URL: string = '/Profile/AddUpdateAdoptionInterestByFamilyID';

  readonly GET_CHILD_CHARACTERISTIC_PREFERENCE_URL: string = '/Profile/GetChildCharacteristicPreferences?familyId=';
  readonly UPDATE_CHARACTERISTIC_PREFERENCE_URL: string = '/Profile/UpdateChildCharacteristicPreferences';

  readonly GET_AGENCY_HISTORY_URL: string = '/Profile/GetHomeAgencyByFamilyID?familyId=';
  readonly UPDATE_AGENCY_HISTORY_URL: string = '/Profile/AddUpdateHomeAgencyByFamilyID';


  public familyProfilePageLookups: any = null;
  public parentalExpectationsLookup: any = null;
  public childCharacteristicPreferencesLookup: any = null;
  public adoptionInterestsLookup: any = null;

  constructor(private http: HttpClient) {
  }

  async getFamilyProfilePageLookups(): Promise<any> {
    if (this.familyProfilePageLookups != null)
      return this.familyProfilePageLookups;
    return await this.http.get(`${this.profilePageApi}${this.FAMILY_PROFILE_LOOKUPS_URL}`).toPromise();
  }

  async getFamilyProfilePageLookupsES(): Promise<any> {
    return await this.http.get(this.FAMILY_PROFILE_LOOKUPS_URL_SPANISH).toPromise();
  }

  async getParentalExpectationsLookups(): Promise<any> {
    if (this.parentalExpectationsLookup != null)
      return this.parentalExpectationsLookup;
    return await this.http.get(`${this.profilePageApi}${this.PARENTAL_EXPECTATIONS_LOOKUPS_URL}`).toPromise();
  }

  async getParentalExpectationsLookupsES(): Promise<any> {
    return await this.http.get(this.PARENTAL_EXPECTATIONS_LOOKUPS_URL_SPANISH).toPromise();
  }

  async getChildCharacteristicPreferencesLookups(): Promise<any> {
    if (this.childCharacteristicPreferencesLookup != null)
      return this.childCharacteristicPreferencesLookup;
    return await this.http.get(`${this.profilePageApi}${this.CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL}`).toPromise();
  }

  async getChildCharacteristicPreferencesLookupsES(): Promise<any> {
    return await this.http.get(this.CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL_SPANISH).toPromise();
  }

  async getAdoptionInterestsLookups(): Promise<any> {
    if (this.adoptionInterestsLookup != null)
      return this.adoptionInterestsLookup;
    return await this.http.get(`${this.profilePageApi}${this.ADOPTION_INTERESTS_LOOKUPS_URL}`).toPromise();
  }

  async getAdoptionInterestsLookupsES(): Promise<any> {
    return await this.http.get(this.ADOPTION_INTERESTS_LOOKUPS_URL_SPANISH).toPromise();
  }

  getFamilyProfile(familyId: number) {
    return this.http.get(`${this.profilePageApi}${this.GET_FAMILY_PROFILE_URL}${familyId}`);
  }

  getFamilyProfileByEmail() {
    return this.http.get(`${this.profilePageApi}${this.GET_FAMILY_PROFILE_BY_EMAIL_TOKEN_URL}`);
  }

  saveFamilyProfile(familyProfileRequest) {
    return familyProfileRequest.familyId ? this.http.put(`${this.profilePageApi}${this.UPDATE_FAMILY_PROFILE_URL}`, familyProfileRequest) :
      this.http.post(`${this.profilePageApi}${this.CREATE_FAMILY_PROFILE_URL}`, familyProfileRequest)
  }

  addUpdateFamilyMember(familyMemberRequest) {
    return familyMemberRequest.familyMemberId ? this.http.put<FamilyMember>(`${this.profilePageApi}${this.UPDATE_FAMILY_MEMBER_URL}`, familyMemberRequest) :
      this.http.post<FamilyMember>(`${this.profilePageApi}${this.ADD_FAMILY_MEMBER_URL}`, familyMemberRequest);
  }

  deleteFamilyMember(familyMemberId) {
    return this.http.delete<FamilyMember>(`${this.profilePageApi}${this.DELETE_FAMILY_MEMBER_URL}${familyMemberId}`);
  }

  getFileUploadUrls() {
    return {
      getUrl: '',
      deleteUrl: '',
      downloadUrl: '',
      uploadUrl: `${this.searchPageApi}${this.UPLOAD_FAMILY_PROFILE_IMAGE_URL}`,
      linkImageWithAzureUrl: `${this.profilePageApi}${this.LINK_FAMILY_WITH_AZURE_IMAGE}`
    };
  }

  saveParentalExpectations(request) {
    return this.http.put(`${this.profilePageApi}${this.UPDATE_PARENTAL_EXPECTATIONS_URL}`, request);
  }

  getAdoptionInterests(familyId: number) {
    return this.http.get(`${this.profilePageApi}${this.GET_ADOPTION_INTERESTS_URL}${familyId}`);
  }

  saveAdoptionInterests(request) {
    return this.http.put(`${this.profilePageApi}${this.UPDATE_ADOPTION_INTERESTS_URL}`, request);
  }

  /* getChildCharacteristicPreferences(familyId: number) {
    return this.http.get(`${this.profilePageApi}${this.GET_CHILD_CHARACTERISTIC_PREFERENCE_URL}${familyId}`);
  } */

  saveChildCharacteristicPreferences(request) {
    return this.http.put(`${this.profilePageApi}${this.UPDATE_CHARACTERISTIC_PREFERENCE_URL}`, request);
  }

  getAgencyHistory(familyId: number) {
    return this.http.get(`${this.profilePageApi}${this.GET_AGENCY_HISTORY_URL}${familyId}`);
  }

  saveAgencyHistory(request) {
    return this.http.put(`${this.profilePageApi}${this.UPDATE_AGENCY_HISTORY_URL}`, request);
  }

}
