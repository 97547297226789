import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from '../home.service';
import { ActivatedRoute } from '@angular/router';
import { AppUtilService } from '@shared/services/app-util.service';
import { ChildSearchService } from 'app/modules/child/services/child.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-inquiry-details',
  templateUrl: './inquiry-details.component.html',
  styleUrls: [
    "../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './inquiry-details.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class InquiryDetailsComponent implements OnInit {

  inquiryId: number;
  inquiryDetailsResponse: any;
  activeLangSpanish$: Observable<boolean>;
  inquiryLookup$: Observable<any>;

  constructor(
    private toasterService: ToastrService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private appUtilService: AppUtilService,
    private childService: ChildSearchService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParam => {
      this.inquiryId = +queryParam.get('inquiryId');
    });
    this.homeService.getInquiryDetails(this.inquiryId).subscribe((res) => {
      this.inquiryDetailsResponse = { ...res };

      this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
        this.activeLangSpanish$ = of(value);
        if (value && this.inquiryDetailsResponse) {
          this.childService.getChildSearchPageLookupsES().then(data => {
            this.inquiryLookup$ = data;
            this.inquiryDetailsResponse.gender = this.appUtilService.convertAPIRespTxtToESFromLookups(this.inquiryLookup$['gender2'], this.inquiryDetailsResponse?.gender, 'gender');
            this.inquiryDetailsResponse.race = this.appUtilService.convertAPIRespTxtToESFromLookups(this.inquiryLookup$['race'], this.inquiryDetailsResponse?.race, 'race');
            this.inquiryDetailsResponse.ethnicity = this.appUtilService.convertAPIRespTxtToESFromLookups(this.inquiryLookup$['ethnicity'], this.inquiryDetailsResponse?.ethnicity, 'ethnicity');
            this.inquiryDetailsResponse.region = this.appUtilService.convertAPIRespTxtToESFromLookups(this.inquiryLookup$['regions'], this.inquiryDetailsResponse?.region, 'regions');
            this.inquiryDetailsResponse.primaryLanguage = this.appUtilService.convertAPIRespTxtToESFromLookups(this.inquiryLookup$['languages'], this.inquiryDetailsResponse?.primaryLanguage, 'languages');
          });
        } else {
          this.childService.getChildSearchPageLookups().then(data => {
            this.childService.searchPageLookups = data;
            this.inquiryLookup$ = data;
            this.inquiryDetailsResponse = { ...res };
          });
        }
      });
    },
      error => {
        this.toasterService.error(error, 'getInquiryDetails Error');
      });
  }

}
