import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DfpsFormValidationDirective, DirtyCheck, FormValidationError, SET, DfpsCommonValidators } from 'dfps-web-lib';
import { ToastrService } from 'ngx-toastr';
import { AppInsightMonitoringService } from '@shared/services/AppInsightMonitoringService';
import { FamilyProfileService } from '../../services/family.service';
import { Store } from '@ngrx/store';
import { SharedValidator } from '@shared/validators/shared.validator';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddFamilyMemberComponent } from './add-family-member/add-family-member.component';
import { FamilyProfile } from '../../model/family';
import { DatePipe } from '@angular/common';
import { AppUtilService } from '@shared/services/app-util.service';
import { DataShareService } from '@shared/services/data-share.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './family-info.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/ngx-bootstrap/datepicker/bs-datepicker.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    "../../../../../.././node_modules/primeicons/primeicons.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './family-info.component.css'
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class FamilyInfoComponent extends DfpsFormValidationDirective implements OnInit {

  @ViewChild('errors', { static: false }) errorElement: ElementRef;

  selectedValue = new FormControl(1);
  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  familyProfileForm: FormGroup;
  requestProcessing: Boolean;
  selectedRace: any[];
  selectedSpouseRace: any[];
  displayFamilyProfileDetails: FamilyProfile;
  preSelectedRaceValues: string[];
  preSelectedSpouseRaceValues: string[];
  familyProfileLookup$: Observable<any>;
  enableUpdateDeleteButton: boolean;
  familyProfileSuccessMsg: string;
  familyMembersTableColumns: { field: string; header: string; filterMatchMode: string; width: number }[];
  familyMembersTableTotalRecords: any;
  familyMembersTableData: any;
  familyMembersTableFilterToggleInd: boolean;
  private readonly _destroy = new Subject<void>();
  renderFamilyProfile: boolean;

  maxDate = { year: new Date().getUTCFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  minDate = { year: new Date().getUTCFullYear() - 120, month: 12, day: 31 }

  constructor(
    private familyProfileService: FamilyProfileService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private monitoringService: AppInsightMonitoringService,
    public store: Store<{ dirtyCheck: DirtyCheck }>,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private appUtilService: AppUtilService,
    private shareService: DataShareService,
    private router: Router) {
    super(store);
  }

  createForm() {
    this.familyProfileForm = this.formBuilder.group({
      familyId: [],
      familyMemberId: [],
      firstName: ['', [Validators.required]],
      middleName: [''],
      lastName: ['', [Validators.required]],
      suffix: [''],
      dateOfBirth: [''],
      gender: [''],
      race: [[]],
      ethnicity: [''],
      maritalStatus: [''],
      dateOfMarriage: ['', [DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]],
      dateOfDivorce: ['', [DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]],
      spouseFamilyMemberId: [],
      spouseFirstName: [''],
      spouseMiddleName: [''],
      spouseLastName: [''],
      spouseSuffix: [''],
      spouseDateOfBirth: ['', [DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]],
      spouseGender: [''],
      spouseRace: [[]],
      spouseEthnicity: [''],
      emailAddress: ['', [Validators.required, SharedValidator.validateEmailAddress]],
      primaryPhone: ['', [Validators.required, SharedValidator.phoneNumberPattern]],
      secondaryPhone: ['', [SharedValidator.phoneNumberPattern]],
      mailingAddress0: ['', [Validators.required]],
      mailingAddress1: [''],
      mailingCity: ['', [Validators.required, DfpsCommonValidators.cityPattern]],
      mailingState: ['TX', [Validators.required]],
      mailingCounty: ['', [Validators.required]],
      mailingZip: ['', [Validators.required, DfpsCommonValidators.zipPattern]],
      sameMailingAddress: [false],
      physicalAddress0: ['', [Validators.required]],
      physicalAddress1: [''],
      physicalCity: ['', [Validators.required, DfpsCommonValidators.cityPattern]],
      physicalState: ['TX', [Validators.required]],
      physicalCounty: ['', [Validators.required]],
      physicalZip: ['', [Validators.required, DfpsCommonValidators.zipPattern]],
      whoReferred: ['', [Validators.required]],
      referralOther: [''],
      parentingInterest: ['', [Validators.required]],
      primaryLanguage: [''],
      primaryLanguageOther: [''],
      secondaryLanguage: [''],
      secondaryLanguageOther: [''],
      optOutOfEmails: [false]
    });
  }

  selectedRaceValues(memberTrpe: string, event) {
    if (memberTrpe === 'race') {
      this.selectedRace = [...event];
      this.familyProfileForm.patchValue({ race: this.selectedRace });
    }
    else {
      this.selectedSpouseRace = [...event];
      this.familyProfileForm.patchValue({ spouseRace: this.selectedSpouseRace });
    }
  }

  ngOnInit(): void {
    this.renderFamilyProfile = false;
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.familyProfileService.getFamilyProfilePageLookupsES().then(data => {
          this.familyProfileLookup$ = data;
        });
      } else {
        this.familyProfileService.getFamilyProfilePageLookups().then(data => {
          this.familyProfileService.familyProfilePageLookups = data;
          this.familyProfileLookup$ = data;
        });
      }
    });
    this.enableUpdateDeleteButton = false;
    this.createForm();
    if (this.shareService.getDataFromMap('familyProfileInfo') && this.shareService.getDataFromMap('familyProfileInfo').familyId) {
      this.initializeDataTable();
      this.patchFamilProfileData();
    } else if (this.shareService.getDataFromMap('familyProfileInfo')) {
      const familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');
      this.familyProfileForm.patchValue({
        userName: familyProfileInfo.userName,
        firstName: familyProfileInfo.firstName,
        middleName: familyProfileInfo.middleName,
        lastName: familyProfileInfo.lastName,
        emailAddress: familyProfileInfo.emailAddress,
        primaryPhone: familyProfileInfo?.primaryPhone,
        secondaryPhone: familyProfileInfo?.secondaryPhone,
        mailingAddress0: familyProfileInfo.mailingAddress0,
        mailingAddress1: familyProfileInfo.mailingAddress1,
        mailingCity: familyProfileInfo.mailingCity,
        mailingState: ((familyProfileInfo.mailingState === '' && familyProfileInfo.mailingState == null) ? 'TX' : familyProfileInfo.mailingState),
        mailingCounty: ((familyProfileInfo.mailingState === 'TX') ? familyProfileInfo.mailingCounty : '999'),
        mailingZip: familyProfileInfo.mailingZip,
        sameMailingAddress: familyProfileInfo.sameMailingAddress,
        physicalAddress0: familyProfileInfo.physicalAddress0,
        physicalAddress1: familyProfileInfo.physicalAddress1,
        physicalCity: familyProfileInfo.physicalCity,
        physicalState: ((familyProfileInfo.physicalState === '' && familyProfileInfo.physicalState == null) ? 'TX' : familyProfileInfo.physicalState),
        physicalCounty: ((familyProfileInfo.mailingState === 'TX') ? familyProfileInfo.physicalCounty : '999'),
        physicalZip: familyProfileInfo.physicalZip,
      });
      this.renderFamilyProfile = true;
    } else {
      this.renderFamilyProfile = true;
    }

    this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
  }

  initializeDataTable() {
    this.familyMembersTableColumns = [
      { field: 'firstname', header: 'First Name', filterMatchMode: 'contains', width: 200 },
      { field: 'lastname', header: 'Last Name', filterMatchMode: 'contains', width: 200 },
      { field: 'dT_BIRTH', header: 'Date of Birth', filterMatchMode: 'contains', width: 125 },
      { field: 'age', header: 'Age', filterMatchMode: 'contains', width: 80 },
      { field: 'gender', header: 'Gender', filterMatchMode: 'contains', width: 100 },
      { field: 'relation', header: 'Relation', filterMatchMode: 'contains', width: 90 },
    ];
  }

  patchFamilProfileData() {
    this.familyProfileService.getFamilyProfileByEmail().subscribe((res) => {
      if (res && (res != null)) {
        this.displayFamilyProfileDetails = res;
        const detailsResponse: any = this.displayFamilyProfileDetails;
        if (detailsResponse.familyMembers && detailsResponse.familyMembers.length > 0) {
          this.familyMembersTableData = detailsResponse.familyMembers;
          this.familyMembersTableTotalRecords = this.familyMembersTableData.length;
        }
        this.familyProfileForm.patchValue({
          familyId: detailsResponse.familyId,
          familyMemberId: detailsResponse.familyMemberId,
          firstName: detailsResponse.firstName,
          middleName: detailsResponse.middleName,
          lastName: detailsResponse.lastName,
          suffix: detailsResponse.suffix,
          dateOfBirth: detailsResponse.dateOfBirth ? this.datePipe.transform(detailsResponse.dateOfBirth, 'MM/dd/yyyy') : null,
          gender: detailsResponse.gender,
          race: detailsResponse.race ? detailsResponse.race : [],
          ethnicity: detailsResponse.ethnicity ? detailsResponse.ethnicity : '',
          spouseFamilyMemberId: detailsResponse.spouseFamilyMemberId,
          spouseFirstName: detailsResponse.spouseFirstName,
          spouseMiddleName: detailsResponse.spouseMiddleName,
          spouseLastName: detailsResponse.spouseLastName,
          spouseSuffix: detailsResponse.spouseSuffix,
          spouseDateOfBirth: detailsResponse.spouseDateOfBirth ? this.datePipe.transform(detailsResponse.spouseDateOfBirth, 'MM/dd/yyyy') : null,
          spouseGender: detailsResponse.spouseGender,
          spouseRace: detailsResponse.spouseRace ? detailsResponse.spouseRace : [],
          spouseEthnicity: detailsResponse.spouseEthnicity ? detailsResponse.spouseEthnicity : '',
          maritalStatus: detailsResponse.maritalStatus,
          dateOfMarriage: detailsResponse.dateOfMarriage ? this.datePipe.transform(detailsResponse.dateOfMarriage, 'MM/dd/yyyy') : null,
          dateOfDivorce: detailsResponse.dateOfDivorce ? this.datePipe.transform(detailsResponse.dateOfDivorce, 'MM/dd/yyyy') : null,
          emailAddress: detailsResponse.emailAddress,
          primaryPhone: detailsResponse?.primaryPhone,
          secondaryPhone: detailsResponse?.secondaryPhone,
          mailingAddress0: detailsResponse.mailingAddress0,
          mailingAddress1: detailsResponse.mailingAddress1,
          mailingCity: detailsResponse.mailingCity,
          mailingState: ((detailsResponse.mailingState === '' && detailsResponse.mailingState == null) ? 'TX' : detailsResponse.mailingState),
          mailingCounty: ((detailsResponse.mailingState === 'TX') ? detailsResponse.mailingCounty : '999'),
          mailingZip: detailsResponse.mailingZip,
          sameMailingAddress: detailsResponse.sameMailingAddress,
          physicalAddress0: detailsResponse.physicalAddress0,
          physicalAddress1: detailsResponse.physicalAddress1,
          physicalCity: detailsResponse.physicalCity,
          physicalState: ((detailsResponse.physicalState === '' && detailsResponse.physicalState == null) ? 'TX' : detailsResponse.physicalState),
          physicalCounty: ((detailsResponse.mailingState === 'TX') ? detailsResponse.physicalCounty : '999'),
          physicalZip: detailsResponse.physicalZip,
          whoReferred: detailsResponse.whoReferred,
          referralOther: detailsResponse.referralOther,
          parentingInterest: detailsResponse.parentingInterest,
          primaryLanguage: detailsResponse.primaryLanguage,
          primaryLanguageOther: detailsResponse.primaryLanguageOther,
          secondaryLanguage: detailsResponse.secondaryLanguage ? (detailsResponse.secondaryLanguage === 'N/A') ? '' : detailsResponse.secondaryLanguage : '',
          secondaryLanguageOther: detailsResponse.secondaryLanguageOther,
          optOutOfEmails: detailsResponse.optOutOfEmails === 'Y' ? [true] : false
        });
        this.preSelectedRaceValues = detailsResponse.race ? detailsResponse.race : [];
        this.preSelectedSpouseRaceValues = detailsResponse.spouseRace ? detailsResponse.spouseRace : [];
        this.familyProfileForm.get('dateOfBirth').setValidators([Validators.required, DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]);
        this.familyProfileForm.get('race').setValidators([Validators.required]);
        this.familyProfileForm.get('maritalStatus').setValidators([Validators.required]);
        this.familyProfileForm.get('primaryLanguage').setValidators([Validators.required]);
        this.maritalStatusChange();
        this.primaryLanguageOther();
        this.secondaryLanguageOther();

        this.renderFamilyProfile = true;
      }
      this.referralOther();
    },
      error => {
        this.toasterService.error(error, 'getFamilyProfileByEmail Error');
        this.renderFamilyProfile = true;
      });

  }

  sameMailingAddress() {
    if (this.familyProfileForm.value.sameMailingAddress) {
      this.familyProfileForm.patchValue({
        physicalAddress0: this.familyProfileForm.value.mailingAddress0,
        physicalAddress1: this.familyProfileForm.value.mailingAddress1,
        physicalCity: this.familyProfileForm.value.mailingCity,
        physicalState: this.familyProfileForm.value.mailingState,
        physicalCounty: this.familyProfileForm.value.mailingCounty,
        physicalZip: this.familyProfileForm.value.mailingZip,
      });
    } else {
      this.familyProfileForm.patchValue({
        physicalAddress0: this.familyProfileForm.value.mailingAddress0 ? this.familyProfileForm.value.mailingAddress0 : '',
        physicalAddress1: this.familyProfileForm.value.mailingAddress1 ? this.familyProfileForm.value.mailingAddress1 : '',
        physicalCity: this.familyProfileForm.value.mailingCity ? this.familyProfileForm.value.mailingCity : '',
        physicalState: this.familyProfileForm.value.mailingState ? this.familyProfileForm.value.mailingState : '',
        physicalCounty: this.familyProfileForm.value.mailingCounty ? this.familyProfileForm.value.mailingCounty : '',
        physicalZip: this.familyProfileForm.value.mailingZip ? this.familyProfileForm.value.mailingZip : '',
      });
    }
  }

  maritalStatusChange() {
    if (this.familyProfileForm.value.maritalStatus === 'MA') {
      this.familyProfileForm.get('dateOfMarriage').setValidators([Validators.required, DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]);
      this.familyProfileForm.get('dateOfMarriage').updateValueAndValidity();
      this.familyProfileForm.get('spouseFirstName').setValidators([Validators.required]);
      this.familyProfileForm.get('spouseFirstName').updateValueAndValidity();
      this.familyProfileForm.get('spouseLastName').setValidators([Validators.required]);
      this.familyProfileForm.get('spouseLastName').updateValueAndValidity();
      this.familyProfileForm.get('spouseDateOfBirth').setValidators([Validators.required, DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]);
      this.familyProfileForm.get('spouseDateOfBirth').updateValueAndValidity();
      this.familyProfileForm.get('spouseRace').setValidators([Validators.required]);
      this.familyProfileForm.get('spouseRace').updateValueAndValidity();
      this.familyProfileForm.get('dateOfDivorce').clearValidators();
      this.familyProfileForm.get('dateOfDivorce').updateValueAndValidity();
      this.familyProfileForm.patchValue({ dateOfDivorce: '' });
      this.familyProfileForm.get('dateOfDivorce').clearValidators();
    } else if (this.familyProfileForm.value.maritalStatus === 'DI') {
      this.familyProfileForm.get('dateOfDivorce').setValidators([Validators.required, DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]);
      this.familyProfileForm.patchValue({
        dateOfMarriage: '',
        spouseFirstName: '',
        spouseLastName: '',
        spouseDateOfBirth: '',
        spouseRace: '',
      });
      this.familyProfileForm.get('dateOfDivorce').updateValueAndValidity();
      this.familyProfileForm.get('dateOfMarriage').clearValidators();
      this.familyProfileForm.get('dateOfMarriage').updateValueAndValidity();
      this.familyProfileForm.get('spouseFirstName').clearValidators();
      this.familyProfileForm.get('spouseFirstName').updateValueAndValidity();
      this.familyProfileForm.get('spouseLastName').clearValidators();
      this.familyProfileForm.get('spouseLastName').updateValueAndValidity();
      this.familyProfileForm.get('spouseDateOfBirth').clearValidators();
      this.familyProfileForm.get('spouseDateOfBirth').updateValueAndValidity();
      this.familyProfileForm.get('spouseRace').clearValidators();
      this.familyProfileForm.get('spouseRace').updateValueAndValidity();
    } else {
      this.familyProfileForm.patchValue({
        dateOfDivorce: '',
        dateOfMarriage: '',
        spouseFirstName: '',
        spouseLastName: '',
        spouseDateOfBirth: '',
        spouseRace: '',
      });
      this.familyProfileForm.get('dateOfDivorce').clearValidators();
      this.familyProfileForm.get('dateOfDivorce').updateValueAndValidity();
      this.familyProfileForm.get('dateOfMarriage').clearValidators();
      this.familyProfileForm.get('dateOfMarriage').updateValueAndValidity();
      this.familyProfileForm.get('spouseFirstName').clearValidators();
      this.familyProfileForm.get('spouseFirstName').updateValueAndValidity();
      this.familyProfileForm.get('spouseLastName').clearValidators();
      this.familyProfileForm.get('spouseLastName').updateValueAndValidity();
      this.familyProfileForm.get('spouseDateOfBirth').clearValidators();
      this.familyProfileForm.get('spouseDateOfBirth').updateValueAndValidity();
      this.familyProfileForm.get('spouseRace').clearValidators();
      this.familyProfileForm.get('spouseRace').updateValueAndValidity();
    }
  }
  primaryLanguageOther() {
    if (this.familyProfileForm.value.primaryLanguage === 'XX') {
      this.familyProfileForm.get('primaryLanguageOther').setValidators([Validators.required]);
      this.familyProfileForm.get('primaryLanguageOther').updateValueAndValidity();
    } else {
      this.familyProfileForm.patchValue({ primaryLanguageOther: '' });
      this.familyProfileForm.get('primaryLanguageOther').clearValidators();
      this.familyProfileForm.get('primaryLanguageOther').updateValueAndValidity();
    }
  }
  secondaryLanguageOther() {
    if (this.familyProfileForm.value.secondaryLanguage === 'XX') {
      this.familyProfileForm.get('secondaryLanguageOther').setValidators([Validators.required]);
      this.familyProfileForm.get('secondaryLanguageOther').updateValueAndValidity();
    } else {
      this.familyProfileForm.patchValue({ secondaryLanguageOther: '' });
      this.familyProfileForm.get('secondaryLanguageOther').clearValidators();
      this.familyProfileForm.get('secondaryLanguageOther').updateValueAndValidity();
    }
  }
  referralOther() {
    if (this.familyProfileForm.value.whoReferred === '75') {
      this.familyProfileForm.get('referralOther').setValidators([Validators.required]);
      this.familyProfileForm.get('referralOther').updateValueAndValidity();
    } else {
      this.familyProfileForm.patchValue({ referralOther: '' });
      this.familyProfileForm.get('referralOther').clearValidators();
      this.familyProfileForm.get('referralOther').updateValueAndValidity();
    }
  }

  changeState() {
    if (this.familyProfileForm.value.mailingState !== 'TX') {
      this.familyProfileForm.patchValue({ mailingCounty: '999' });
    } else if (this.familyProfileForm.value.mailingState == 'TX' && this.familyProfileForm.value.mailingCounty === '999') {
      this.familyProfileForm.patchValue({ mailingCounty: '' });
    }
    if (this.familyProfileForm.value.physicalState !== 'TX') {
      this.familyProfileForm.patchValue({ physicalCounty: '999' });
    } else if (this.familyProfileForm.value.physicalState == 'TX' && this.familyProfileForm.value.physicalCounty === '999') {
      this.familyProfileForm.patchValue({ physicalCounty: '' });
    }
  }

  changeCounty() {
    if (this.familyProfileForm.value.mailingState !== 'TX' && this.familyProfileForm.value.mailingCounty !== '999') {
      this.familyProfileForm.patchValue({ mailingCounty: '999' });
      alert('County selection not available outside of Texas');
    } else if (this.familyProfileForm.value.mailingState == 'TX' && this.familyProfileForm.value.mailingCounty === '999') {
      this.familyProfileForm.patchValue({ mailingCounty: '' });
    }
    if (this.familyProfileForm.value.physicalState !== 'TX' && this.familyProfileForm.value.physicalCounty !== '999') {
      this.familyProfileForm.patchValue({ physicalCounty: '999' });
      alert('County selection not available outside of Texas');
    } else if (this.familyProfileForm.value.physicalState == 'TX' && this.familyProfileForm.value.physicalCounty === '999') {
      this.familyProfileForm.patchValue({ physicalCounty: '' });
    }
  }

  pasteNumberOnly(event): void {
    SharedValidator.pasteNumberOnly(event);
  }

  validateNumberOnly(event): void {
    SharedValidator.validateNumberOnly(event);
  }

  transformDates() {
    this.familyProfileForm.value.dateOfBirth =
      (this.familyProfileForm.value.dateOfBirth && this.familyProfileForm.value.dateOfBirth != '' && this.familyProfileForm.value.dateOfBirth != null) ?
        this.datePipe.transform(this.familyProfileForm.value.dateOfBirth, 'YYYY-MM-dd') : null;
    this.familyProfileForm.value.spouseDateOfBirth =
      (this.familyProfileForm.value.spouseDateOfBirth && this.familyProfileForm.value.spouseDateOfBirth != '' && this.familyProfileForm.value.spouseDateOfBirth != null) ?
        this.datePipe.transform(this.familyProfileForm.value.spouseDateOfBirth, 'YYYY-MM-dd') : null;
    this.familyProfileForm.value.dateOfMarriage =
      (this.familyProfileForm.value.dateOfMarriage && this.familyProfileForm.value.dateOfMarriage != '' && this.familyProfileForm.value.dateOfMarriage != null) ?
        this.datePipe.transform(this.familyProfileForm.value.dateOfMarriage, 'YYYY-MM-dd') : null;
    this.familyProfileForm.value.dateOfDivorce =
      (this.familyProfileForm.value.dateOfDivorce && this.familyProfileForm.value.dateOfDivorce != '' && this.familyProfileForm.value.dateOfDivorce != null) ?
        this.datePipe.transform(this.familyProfileForm.value.dateOfDivorce, 'YYYY-MM-dd') : null;
  };

  onSaveFamilyProfile() {
    this.maritalStatusChange();
    this.primaryLanguageOther();
    this.secondaryLanguageOther();
    this.sameMailingAddress()
    this.referralOther();
    if (this.validateFormGroup(this.familyProfileForm)) {
      this.requestProcessing = true;
      this.transformDates();
      this.familyProfileForm.value.optOutOfEmails = this.familyProfileForm.value.optOutOfEmails ? this.familyProfileForm.value.optOutOfEmails = 'Y' : this.familyProfileForm.value.optOutOfEmails = 'N';
      this.familyProfileService.saveFamilyProfile(this.familyProfileForm.value).subscribe((res) => {
        if (res) {
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          if (!this.familyProfileForm.value.familyId) {
            this.router.navigate(['/familyProfile/familyInfo'])
              .then(() => {
                window.location.reload();
              });
          } else {
            this.shareService.addDataToMap('familyProfileSuccessMsg', this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a'));
            this.familyProfileSuccessMsg = this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a');
            this.appUtilService.familyProfileInfo$ = null;
            this.shareService.removeDataFromMap('familyProfileInfo');
            this.appUtilService.getFamilyProfileInfo().then(() => { this.ngOnInit(); });
          }
        }
        this.requestProcessing = false;
        window.scrollTo(0, 0);
      }, error => {
        this.requestProcessing = false;
        this.toasterService.error(error, 'saveFamilyProfile Error');
      });
    } else {
      if (this.errorElement) {
        // for focussing errors.
        setTimeout(() => {
          this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus();
        }, 1000);
      }
    }
  }

  uploadFiles() {
    const fileUploadinfo = {
      modalHeading: 'Photo',
      sectionHeading: 'Change Photo',
      id: this.familyProfileForm.value.familyId,
      urls: this.familyProfileService.getFileUploadUrls(),
      disabled: false,
      accept: 'image/*',
      fileLimit: 1,
      multiple: false
    };
    const modal: NgbModalRef =
      this.modalService.open(FileUploadComponent, {
        windowClass: 'uploadDocs',
        size: 'lg',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        ariaLabelledBy: 'modalHeader'
      });
    ((modal.componentInstance) as FileUploadComponent).fileUploadinfo = fileUploadinfo;
    modal.componentInstance.emitFileUploadActions.subscribe(($e) => {
      this.familyProfileService.getFamilyProfileByEmail().subscribe((res) => {
        if (res && (res != null)) this.displayFamilyProfileDetails = res;
      }, error => { this.toasterService.error(error, 'Get Family Profile By Email Error'); });
    });
  }

  addUpdateDeleteFamilyMember(actionType: string, selectedRow?) {
    let addFamilyInfo: any = {};
    switch (actionType) {
      case 'add':
        addFamilyInfo = {
          sectionHeading: 'Family Member Details',
          modalHeading: 'Add Family Member',
          familyMemberDetails: { familyId: this.familyProfileForm.value.familyId, familyMemberId: this.familyProfileForm.value.familyMemberId },
          actionType: actionType
        };
        this.addUpdateModal(addFamilyInfo);
        break;
      case 'update':
        addFamilyInfo = {
          sectionHeading: 'Family Member Details',
          modalHeading: 'Update Family Member',
          familyMemberDetails: selectedRow,
          actionType: actionType
        };
        this.addUpdateModal(addFamilyInfo);
        break;
      case 'delete':
        this.familyProfileService.deleteFamilyMember(selectedRow.familyMemberId).subscribe((res) => {
          if (res) {
            this.ngOnInit();
          }
        },
          error => {
            this.toasterService.error(error, 'deleteFamilyMember Error');
          });
        break;
    }
  }

  addUpdateModal(addFamilyInfo: any) {
    const modal: NgbModalRef =
      this.modalService.open(AddFamilyMemberComponent, {
        windowClass: 'uploadDocs',
        size: 'lg',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        ariaLabelledBy: 'modalHeader'
      });
    ((modal.componentInstance) as AddFamilyMemberComponent).addFamilyInfo = addFamilyInfo;
    modal.componentInstance.emitAddFamilyActions.subscribe(($e) => {
      this.ngOnInit();
    });
  }

  filterTogglefamilyMembersTable(dt) {
    dt.reset();
    this.familyMembersTableFilterToggleInd = !this.familyMembersTableFilterToggleInd;
  }

  getTableColumnAriaLabel(colHeader: string) {
    return `${colHeader} Column Filter`;
  }

}
