import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChildrenSearchResponse } from '@shared/model/child';
import { environment } from 'environments/environment';
import { ChildrenSearchRequest } from '../model/child-search';
@Injectable({
  providedIn: 'root'
})
export class ChildSearchService {
  profilePageApi = environment.profilePageApi;
  searchPageApi = environment.searchPageApi;
  profilePageApiAnonymous = environment.profilePageApiAnonymous;
  searchPageApiAnonymous = environment.searchPageApiAnonymous;

  readonly CHILD_SEARCH_LOOKUPS_URL = '/AnonymousLookup/GetSearchChildPageValues';
  readonly CHILDREN_SEARCH_ANONYMOUS_URL: string = '/Search/ChildAnonymousSearch';
  readonly CHILD_PROFILE_ANONYMOUS_URL: string = '/Search/GetAnonymousChildById?childId=';
  readonly SIBLINGS_PROFILE_ANONYMOUS_URL: string = '/Search/GetAnonymousSiblingById?siblingGroupId=';
  readonly CHILDREN_SEARCH_PUBLIC_URL: string = '/Search/ChildPublicSearch';
  readonly CHILD_PROFILE_PUBLIC_URL: string = '/Search/GetPublicChildById?childId=';
  readonly SIBLINGS_PROFILE_PUBLIC_URL: string = '/Search/GetPublicSiblingById?siblingGroupId=';
  readonly FAMILYS_CHILDREN_SEARCH_CRITERIA_URL: string = '/Profile/GetFamilySearchChildCharacteristics';
  readonly ADD_REMOVE_BOOKMARK: string = '/Profile/AddDeleteBookMarksByUserName?childId=';
  readonly INQUIRY_PAGE_LOOKUPS: string = '/PublicLookup/CreateInquiryPageValues';
  readonly CREATE_INQUIRY: string = '/Profile/CreateInquiry';
  readonly CHILD_SEARCH_LOOKUPS_URL_SPANISH: string = '../../../../assets/locales/lookups/es-getSearchChildPageValues.json';
  readonly INQUIRY_PAGE_LOOKUPS_SPANISH: string = '../../../../assets/locales/lookups/es-getInquiryPageLookups.json';
  readonly CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL = '/PublicLookup/GetChildCharacteristicPreferencePageValues';
  readonly CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL_SPANISH = '../../../../assets/locales/lookups/es-getChildCharacteristicPreferencesLookups.json';
  readonly CHILD_CHARACTERISTICS_URL = '/Search/GetPublicChildInterestsByChildId?ChildId=';

  public searchPageLookups: any = null;
  public inquiryPageLookups: any = null;
  public childCharacteristicPreferencesLookup: any = null;

  isUserLoggedIn: boolean;
  constructor(private http: HttpClient) { }

  async getChildSearchPageLookups(): Promise<any> {
    if (this.searchPageLookups != null)
      return this.searchPageLookups;
    return await this.http.get(`${this.profilePageApiAnonymous}${this.CHILD_SEARCH_LOOKUPS_URL}`).toPromise();
  }

  async getChildSearchPageLookupsES(): Promise<any> {
    return await this.http.get(this.CHILD_SEARCH_LOOKUPS_URL_SPANISH).toPromise();
  }

  async getInquiryPageLookups(): Promise<any> {
    if (this.inquiryPageLookups != null)
      return this.inquiryPageLookups;
    return await this.http.get(`${this.profilePageApi}${this.INQUIRY_PAGE_LOOKUPS}`).toPromise();
  }

  async getInquiryPageLookupsES(): Promise<any> {
    return await this.http.get(this.INQUIRY_PAGE_LOOKUPS_SPANISH).toPromise();
  }

  getSavedCriteria(): Observable<ChildrenSearchRequest> {
    return this.http.get(`${this.profilePageApi}${this.FAMILYS_CHILDREN_SEARCH_CRITERIA_URL}`);
  }

  childrenSearch(isUserLoggedIn, childrenSearchRequest: ChildrenSearchResponse)
  /* : Observable<childrenSearchResponse[]>  */ {
    // return this.apiService.post(this.CHILDREN_SEARCH_URL, childrenSearchRequest);
    return this.http.post<ChildrenSearchResponse>(isUserLoggedIn ? `${this.searchPageApi}${this.CHILDREN_SEARCH_PUBLIC_URL}` : `${this.searchPageApiAnonymous}${this.CHILDREN_SEARCH_ANONYMOUS_URL}`, childrenSearchRequest);
  }

  getTAREProfile(isUserLoggedIn, tareId, familyId, isSibling) {
    const url = isUserLoggedIn ? (isSibling ? `${this.searchPageApi}${this.SIBLINGS_PROFILE_PUBLIC_URL}${tareId}&familyId=${familyId}` : `${this.searchPageApi}${this.CHILD_PROFILE_PUBLIC_URL}${tareId}&familyId=${familyId}`) :
      (isSibling ? `${this.searchPageApiAnonymous}${this.SIBLINGS_PROFILE_ANONYMOUS_URL}${tareId}` : `${this.searchPageApiAnonymous}${this.CHILD_PROFILE_ANONYMOUS_URL}${tareId}`);
    return this.http.get(`${url}`);
  }

  getChildInterests(tareId) {
    return this.http.get(`${this.searchPageApi}${this.CHILD_CHARACTERISTICS_URL}${tareId}`);
  }

  async getChildCharacteristicPreferencesLookups(): Promise<any> {
    if (this.childCharacteristicPreferencesLookup != null)
      return this.childCharacteristicPreferencesLookup;
    return await this.http.get(`${this.profilePageApi}${this.CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL}`).toPromise();
  }


  async getChildCharacteristicPreferencesLookupsES(): Promise<any> {
    return await this.http.get(this.CHILD_CHARACTERISTIC_PREFERENCE_LOOKUPS_URL_SPANISH).toPromise();
  }

  addRemoveBookmark(tareId, familyId, isAddBookmark, isSibling) {
    return this.http.get(`${this.profilePageApi}${this.ADD_REMOVE_BOOKMARK}${tareId}&familyid=${familyId}&isAdd=${isAddBookmark}&isSibling=${isSibling}`);
  }

  submitInquiry(submitInquiryPayLoad) {
    return this.http.post(`${this.profilePageApi}${this.CREATE_INQUIRY}`, submitInquiryPayLoad);
  }
}
