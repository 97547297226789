<div class="row pl-3 pt-4">
    <h2 class="col-sm-12 ">Special Needs, Interests and Hobbies</h2>
    <div class="col-sm-12">
        <hr aria-hidden="true" />
    </div>
</div>

<div class="row pl-4 pt-3">
    <p class="col-sm-12 boldBody d-inline-flex mb-0"> We would like to learn more the characteristics of children you
        are able to parent. This information will be used to match you family's strengths with children in Texas waiting
        to be matched with a forever family. For each condition that you and your caseworker have determined you can
        parent, please select the degree of overall disability you could accept. </p>
</div>