import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DataShareService } from '@shared/services/data-share.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-percent-success-msg',
  templateUrl: './profile-percent-success-msg.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './profile-percent-success-msg.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProfilePercentSuccessMsgComponent implements OnInit {

  @Input() familyProfileDetails: any;
  @Input() successMsg: string;
  characteristicType: any;

  constructor(
    private shareService: DataShareService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.successMsg = this.shareService.getDataFromMap('familyProfileSuccessMsg');
    this.characteristicType = this.route.snapshot.data['characteristicsType'];
  }

}
