import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InformationMeetingsService {
    profilePageApiAnonymous = environment.profilePageApiAnonymous;

    public regionLookup: any = null;
    readonly SCHEDULING_LOOKUPS_URL = '/AnonymousLookup/GetSchedulingPageValues';
    readonly SCHEDULING_COUNTY_LIST_URL = '/AnonymousLookup/GetCountyListByRegion';
    readonly SCHEDULED_MEETINGS_INFO_BY_REGION_URL = '/Profile/GetScheduledMeetingsInfobyRegion';

    constructor(private http: HttpClient) { }

    async schedulingPageLookups(): Promise<any> {
        if (this.regionLookup != null)
            return this.regionLookup;
        const url = `${this.profilePageApiAnonymous}${this.SCHEDULING_LOOKUPS_URL}`
        return await this.http.get(url).toPromise();
    }

    schedulingCountiesByRegion(region) {
        return this.http.get(`${this.profilePageApiAnonymous}${this.SCHEDULING_COUNTY_LIST_URL}?region=${region}`);
    }

    getScheduledMeetingsInfobyRegion(region) {
        return this.http.get(`${this.profilePageApiAnonymous}${this.SCHEDULED_MEETINGS_INFO_BY_REGION_URL}?region=${region}`);
    }
}