<div class="modal-content">
    <div class="modal-header">
        <h3 id="modalHeader" class="h5 modal-title">{{addFamilyInfo?.modalHeading}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            &times;
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <h4 class="h5 modal-title">{{addFamilyInfo?.sectionHeading}}</h4>
            </div>
        </div>
        <div #errors>
            <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
                [validationErrorsLength]="validationErrorsLength">
            </dfps-form-validation>
        </div>

        <form [formGroup]="addFamilyMemberForm">
            <div class="row pl-5">
                <div class="col-sm-8">
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="firstname"
                                class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">First
                                Name:</label>
                            <dfps-input class="notranslate" _id="firstname" ariaLabel="First Name"
                                formControlName="firstname" aria-required="true" maxlength="30">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12  d-flex">
                            <label for="lastname"
                                class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Last
                                Name:</label>
                            <dfps-input class="notranslate" _id="lastname" ariaLabel="Last Name"
                                formControlName="lastname" aria-required="true" maxlength="30">
                            </dfps-input>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="dateOfBirth"
                                class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Date of
                                Birth:
                            </label>
                            <dfps-date-picker _id="dateOfBirth" formControlName="dT_BIRTH" name="Date of Birth"
                                aria-required="true" ariaLabel="Date of Birth" [maxDate]="maxDate" [minDate]="minDate"></dfps-date-picker>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label for="gender"
                                class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay">Gender:</label>
                            <dfps-select _id="gender" ariaLabel="Gender" formControlName="gender" aria-required="true"
                                [dropDownValues]="familyMemberLookup$ ? familyMemberLookup$['gender']:[]">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-sm-12 d-flex">
                            <label class="col-sm-4 col-xl-3 col-form-label col-form-label-sm requiredDisplay"
                                for="relation">Relation:</label>
                            <dfps-select _id="relation" ariaLabel="Relation" formControlName="relation"
                                aria-required="true"
                                [dropDownValues]="familyMemberLookup$ ? familyMemberLookup$['relation']:[]">
                            </dfps-select>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 d-flex"> </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <div class="col-sm-12 pt-3">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>

            <dfps-button type="button" class="pull-right" size="medium" label="Save" ariaLabel="Save Family Member"
                _id="saveFamilyMember" (click)="onAddUpdateFamilyMember()"></dfps-button>
        </div>
    </div>
</div>