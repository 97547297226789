import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChildSearchService } from '../../services/child.service';
import { CodesDto } from './codeDto';
import { AppUtilService } from '@shared/services/app-util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cpa-special-needs',
  templateUrl: './cpa-special-needs.component.html',
  styleUrls: ['./cpa-special-needs.component.css']
})
export class CpaSpecialNeedsComponent implements OnInit {

  renderPage: Boolean;
  profileId: number;
  characteristicDetails: any;
  childInterestDetails: any;
  characteristicsType: any;
  specialNeedsDetails: any;
  specialNeedsForm: FormGroup;
  specialNeedsLookup$: Observable<any>;
  filteredHobbies = [];
  hobbies: CodesDto[] = [];
  disableHobbies = false;
  severityMap = new Map();
  familyProfileInfo: any;

  constructor(
    private childrenService: ChildSearchService,
    private toasterService: ToastrService,
    private formBuilder: FormBuilder,
    private appUtilService: AppUtilService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.characteristicsType = this.route.snapshot.data['characteristicsType'];
    this.route.queryParamMap.subscribe(queryParam => {
      this.profileId = +queryParam.get('id');
    });
    this.createForm();
    this.patchSpecialNeeds();
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.childrenService.getChildCharacteristicPreferencesLookupsES().then(data => {
          this.specialNeedsLookup$ = data;
          this.initializeSeverityDecodes();
        });
      } else {
        this.childrenService.getChildCharacteristicPreferencesLookups().then(data => {
          this.specialNeedsLookup$ = data;
          this.initializeSeverityDecodes();
        });
      }
    });
    this.disableHobbies = true;
  }

  createForm() {
    this.specialNeedsForm = this.formBuilder.group({
      hobbies: ['']
    });
  }

  private patchSpecialNeeds() {
    this.childrenService.getChildInterests(this.profileId).subscribe((res) => {
      if (res && (res != null)) {
        this.childInterestDetails = res;
        this.specialNeedsDetails = this.childInterestDetails.specialNeeds;
        if (this.specialNeedsDetails.length > 0) {
          this.specialNeedsDetails.forEach((obj) => {
            if (obj.characteristicType === 'MD') {
              this.characteristicDetails = obj.characteristicDetails;
            }
            if (obj.characteristicType === 'HB') {
              obj.characteristicDetails.forEach(characteristics => {
                if (characteristics.severity === 'Y') {
                  this.filteredHobbies.push(characteristics.childCharacteristicPreferences);
                  this.hobbies.push({ code: characteristics.childCharacteristicPreferences, decode: characteristics.childCharacteristicPreferencesLabel });
                }
              })
            }
          });
        }
      }
    },
      error => {
        this.renderPage = true;
        this.toasterService.error(error.error.message, 'Get Special Needs Error');
      });
  }

  private initializeSeverityDecodes() {
    this.specialNeedsLookup$['severity'].forEach(severity => {
      this.severityMap.set(severity.code, severity.decode);
    })
  }

  getCharacteristicDecode(code) {
    const characteristic = this.specialNeedsLookup$['characteristics'].find(characteristicData => {
      return characteristicData.code === code
    });
    return characteristic ? characteristic.decode : '';
  }

}
