import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfpsButton, DfpsCheckBox, DfpsDirtyCheck, DfpsFormValidation, DfpsInput, DfpsSelect } from 'dfps-web-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CpaAccountComponent } from './cpa-account-register/cpa-account.component';
import { SharedModule } from 'primeng/api';


@NgModule({
    declarations: [
        CpaAccountComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DfpsButton, DfpsDirtyCheck, DfpsFormValidation, DfpsInput,
        DfpsSelect,
        DfpsCheckBox,
        ProgressSpinnerModule,
        NgbModule,
        SharedModule
    ]
})
export class CpaModule { }
