import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class AdoptionInterestsValidators {
    static validateRequiredFields(group: FormGroup) {
        const maxChildrenAdopt = group.controls.maxChildrenAdopt.value;
        const maxAgeChildrenToAdopt = group.controls.maxAgeChildrenToAdopt.value;
        const childAdoptGenderPreference = group.controls.childAdoptGenderPreference.value;
        const childAdoptRacePreference = group.controls.childAdoptRacePreference.value;
        const childAdoptEthnicityPreference = group.controls.childAdoptEthnicityPreference.value;

        if (!maxChildrenAdopt) {
            group.controls.maxChildrenAdopt.setValidators(AdoptionInterestsValidators.setErrorValidator({
                required: { customFieldName: 'Max number of Children willing to Adopt' }
            }));
        } else { group.controls.maxChildrenAdopt.clearValidators(); }
        if (!maxAgeChildrenToAdopt) {
            group.controls.maxAgeChildrenToAdopt.setValidators(AdoptionInterestsValidators.setErrorValidator({
                required: { customFieldName: 'Max age of Children willing to Adopt' }
            }));
        } else { group.controls.maxAgeChildrenToAdopt.clearValidators(); }
        if (!childAdoptGenderPreference) {
            group.controls.childAdoptGenderPreference.setValidators(AdoptionInterestsValidators.setErrorValidator({
                required: { customFieldName: 'Gender of Children willing to Adopt' }
            }));
        } else { group.controls.childAdoptGenderPreference.clearValidators(); }
        if (!childAdoptRacePreference) {
            group.controls.childAdoptRacePreference.setValidators(AdoptionInterestsValidators.setErrorValidator({
                required: { customFieldName: 'Race of Children willing to Adopt' }
            }));
        } else { group.controls.childAdoptRacePreference.clearValidators(); }
        if (!childAdoptEthnicityPreference) {
            group.controls.childAdoptEthnicityPreference.setValidators(AdoptionInterestsValidators.setErrorValidator({
                required: { customFieldName: 'Ethnicity of Children willing to adopt' }
            }));
        } else { group.controls.childAdoptEthnicityPreference.clearValidators(); }

        group.controls.maxChildrenAdopt.updateValueAndValidity({ onlySelf: true });
        group.controls.maxAgeChildrenToAdopt.updateValueAndValidity({ onlySelf: true });
        group.controls.childAdoptGenderPreference.updateValueAndValidity({ onlySelf: true });
        group.controls.childAdoptRacePreference.updateValueAndValidity({ onlySelf: true });
        group.controls.childAdoptEthnicityPreference.updateValueAndValidity({ onlySelf: true });

    }

    static setErrorValidator(errorObject: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return errorObject;
        };
    }
}