import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  dataMap: Map<string, any>;

  constructor() {
    this.dataMap = new Map(); }

    addDataToMap(key: string, value: any) {
      if (key) {
        this.dataMap.set(key, value);
      }
    }

    getDataFromMap(key: string): any {
      let val: any;
      if (key) {
        val = this.dataMap.get(key);
      }
      return val;
    }

    getDataFromMapandRemove(key: string): any {
      let val: any;
      if (key) {
        val = this.dataMap.get(key);
        this.removeDataFromMap(key);
      }
      return val;
    }

    removeDataFromMap(key: string) {
      this.dataMap.delete(key);
    }
}
