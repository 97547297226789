<div class="container-fluid bodycolor">
    <div class="row">
        <h1 class="col-sm-12"> Texas Adoption Resource Exchange (TARE)</h1>
        <div class="col-sm-12">
            <hr aria-hidden="true">
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6 px-5 pt-4">

            <div class="row">
                <div class="col-sm-12">
                    <p>The TARE website helps match children awaiting adoption with adoptive parents.Families need to
                        create an account and complete a family profile in order to view the full profile information
                        and to inquire on children or sibling groups. Refer to the "
                        <strong>
                            <a href="/Adoption_and_Foster_Care/pdf/TARE_Family_User_Manual.pdf">User Guide</a>
                        </strong>" for tips and guidelines on creating an account and completing a family profile.
                    </p>
                    <h2> If you have an account, login below:</h2>
                </div>
                <div class="col-sm-12 d-flex">
                    <div class="col-sm-4 mt-2">
                        <dfps-button _id="login" label="Login" (click)="login()"> </dfps-button>
                    </div>
                    <div class="col-sm-8 mt-2">
                        <p class="mb-0">If you are still having problems Login to TARE,
                            <a href="javascript:obfuscator('tare','dfps.texas.gov');">contact us</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 px-4 pt-4">
            <h2>Benefits of Creating a TARE Login Account:</h2>
            <ul>
                <li>Access to detailed information and photos of children available for adoption in Texas</li>
                <li>Ability to maintain your adoption preferences</li>
                <li>Track children you are interested in and receive updates on your inquiries</li>
                <li>Access to Texas Adoption Resource Exchange staff contact information</li>
            </ul>

            <a routerLink="/" (click)="login()"> Create an Account</a>
        </div>

    </div>
    <div class="row pt-5 px-3">
        <p class="col-sm-12">
            <strong>Warning:</strong> This is a Texas Department of Family Protective Services information resources
            system that contains State and/or U.S. Government information. By using this system you acknowledge and
            agree that you have no right of privacy in connection with your use of the system or your access to the
            information contained within it. By accessing and using this system you are consenting to the monitoring of
            your use of the system, and to security assessment and auditing activities that may be used for law
            enforcement or other legally permissible purposes. Any unauthorized use or access, or any unauthorized
            attempts to use or access, this system may subject you to disciplinary action, sanctions, civil penalties,
            or criminal prosecution to the extent permitted under applicable law.
        </p>
        <p class="col-sm-12">If you are having problems viewing this site, we recommend upgrading to the most
            recent version of your browser. If you are still having problems,
            <a href="javascript:obfuscator('tare','dfps.texas.gov');">contact us</a>.
        </p>
    </div>

</div>